import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/storage'; 
let config = {
	apiKey: "AIzaSyAkVA9kN20RAW_Xpw5NX6DwL139cHwXwpM",
    authDomain: "delibroom.firebaseapp.com",
    databaseURL: "https://delibroom.firebaseio.com",
    projectId: "delibroom",
    storageBucket: "",
    messagingSenderId: "234634568773",
    appId: "1:234634568773:web:5c28ca6984236646"
};

firebase.initializeApp(config)
export default firebase;