import React from "react";
const AnyQuestions = props => {
    return (
        <div className="questions_text">
            <h3 className="title">
                Have any questions? Contact our support center
            </h3>
            <button className="btn bright_blue email" onClick={props.openModal}>
                <span>get in touch</span>
            </button>
        </div>
    );
};

export default AnyQuestions;
