import React from "react";
import ReactGA from 'react-ga';
import AppStore from "../../media/img/appStore.svg"
import Googleplay from "../../media/img/googleplay.svg"

const DownloadLinks = props => {
   let  jumpToAppStore = () => {
        ReactGA.event({
            category: 'appStore',
            action: 'go_to_page',
        });
    }

    let  jumpToGooglePlay = () => {
        ReactGA.event({
            category: 'googlePlay',
            action: 'go_to_page',
        });
    }

    const androidPhone = () => {
       return (
           <div
               className={`download_links_wrap  ${props.otherClass ? props.otherClass : 'email'}`}
               onClick={props.openModal}
           >
               <img src={Googleplay} alt=""/>

               <div className="download_links_wrap_bottom_line">
                   <p className="download_links_wrap_subtitle ">
                       Coming soon in {new Date().getFullYear()}
                   </p>
               </div>
           </div>
       );
    };

    const androidTablet = () => {
        return (
            <div className="download_links_wrap">
                <a
                    href="https://play.google.com/store/apps/details?id=com.delibroom.tablet"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={()=>{jumpToGooglePlay()}}
                >
                    <img src={Googleplay} alt=""/>

                    <div className="download_links_wrap_bottom_line">
                        <p className="download_links_wrap_subtitle " />
                    </div>
                </a>
            </div>
        );
    };

    return (
        <div className="download_links">
            <div className="download_links_wrap">
                <a
                    href={props.device === 'tel' ? 'https://apps.apple.com/app/id1462261055' : (props.device === 'tablet' ? 'https://apps.apple.com/app/id1462260974' : 'https://www.apple.com/itunes/') }
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={()=>{jumpToAppStore()}}
                >
                    <img src={AppStore} alt=""/>

                    <div className="download_links_wrap_bottom_line">
                        <p className="download_links_wrap_subtitle " />
                    </div>
                </a>
            </div>

            { props.device === 'tel' ? androidPhone() : androidTablet() }

        </div>
    );
};

export default DownloadLinks;
