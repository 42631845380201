import React from "react";
import app from "../../../../media/img/app_delibroom.png";

const Icon2 = props => (
  <div className="third_icon">
    <img src={app} className="icon3_app" alt="" />
    <svg
      id="Icon_Arrow"
      x="0px"
      y="0px"
      viewBox="0 0 101 86"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".st0{fill:none;stroke:#4bb4ff}"}</style>
      <path id="Shape_899_copy" className="st0" d="M50.5.5v66" />
      <path id="Shape_900_copy" className="st0" d="M67.5 50.5l-16 18-19-18" />
      <path
        id="Rounded_Rectangle_879"
        className="st0"
        d="M100.5 50.7v27.8c0 3.9-3.1 7-7 7h-86c-3.9 0-7-3.1-7-7V52.4"
      />
    </svg>
  </div>
);

export default Icon2;
