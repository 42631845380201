import React, { Component } from "react";
import LeftNavigation from "../leftNavigation/leftNavigation";
import MainSlider from "../sliders/mainSLider";
import Benefits from "../benefits/benefits";
import BottomSlider from "../sliders/bottomSlider";
import RangeSlider from "../rangeslider/rangeslider";


// import Video from "../video/video";

class Main extends Component {
    state = {
        rate: 5,
        title: "monthly"
    };
    rate = el => {
        this.setState({
            rate: el.currentTarget.getAttribute("data-index"),
            title: el.currentTarget.getAttribute("data-title")
        });
    };

    componentDidMount() {
        this.props.startPresentPage('header')
        document.querySelector("footer").classList.remove("contact_page");
        document.querySelectorAll(".btn_toggle li").forEach(function(e, index) {
            e.addEventListener("click", function(el) {
                el.currentTarget
                    .closest("ul")
                    .querySelector("li.active")
                    .classList.remove("active");
                el.currentTarget.classList.add("active");
            });
        });
        window.addEventListener("scroll", e => {
            if (document.querySelector(".slider:not(.mobileVersion)")) {
                let sliderHeight = document.querySelector(".slider:not(.mobileVersion)").clientHeight;

               if(window.innerWidth <= 1900) {
                    if (document.documentElement.scrollTop >= sliderHeight || document.body.scrollTop >= sliderHeight  ) {
                        document.querySelector(".left_navigation").classList.add("active");
                    } 
                    else {
                        document.querySelector(".left_navigation").classList.remove("active");
                    }
               } else {
                if (document.documentElement.scrollTop >= (sliderHeight/2) || document.body.scrollTop >= (sliderHeight/2)  ) {
                    document.querySelector(".left_navigation").classList.add("active");
                } else {
                    document.querySelector(".left_navigation").classList.remove("active");
                }
            }

            if(window.innerWidth <= 768) {
                if (document.documentElement.scrollTop >= 50 || document.body.scrollTop >= 50  ) {
                    document.querySelector("header").classList.add("shadow");
                } 
                else {
                    document.querySelector("header").classList.remove("shadow");
                }
            }


                if (
                    document.documentElement.scrollTop >= 1 ||
                    document.body.scrollTop >= 1
                ) {
                    document
                        .querySelector(".slider:not(.mobileVersion)")
                        .classList.add("none");
                } else {
                    document
                        .querySelector(".slider:not(.mobileVersion)")
                        .classList.remove("none");
                }
            }

            if (window.innerWidth <= 1025) {
                let benefits = document.getElementById("feature");
                let offsettBennefits =
                    benefits.offsetTop - window.innerHeight / 3;
                let heightBenefits = benefits.clientHeight;
                let endBenefits = offsettBennefits + heightBenefits;
                if (
                    (document.documentElement.scrollTop >= offsettBennefits &&
                        document.documentElement.scrollTop <= endBenefits) ||
                    (document.body.scrollTop >= offsettBennefits &&
                        document.body.scrollTop <= endBenefits)
                ) {
                    benefits.classList.add("active");
                } else {
                    benefits.classList.remove("active");
                }

                let fisrSlide = document.querySelector(".slider:not(.regular)");
                let heightSlide = document.querySelector(
                    ".slider:not(.regular) .slide.one"
                ).clientHeight;

                if (
                    document.documentElement.scrollTop <= heightSlide ||
                    document.body.scrollTop <= heightSlide
                ) {
                    fisrSlide.classList.add("active");
                } else {
                    fisrSlide.classList.remove("active");
                }
            }
        });

        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    render() {
        return (
            <>
                <LeftNavigation />
                <MainSlider openModal={this.props.openModal} />
                <section className="main_content first" id="begin_main_content" >
                    <div className="block_window scheduling" id="how-to-use">
                        <BottomSlider openModal={this.props.openModal} />
                    </div>

                   <Benefits/>
                    <div className="block_window pricing" id="pricing">
                        <div className="pricing_upper_line">
                            <h2 className="block_title">Pricing</h2>
                            <div className="pricing_group">
                                <p className="text">
                                    Choose the plan that works for you
                                </p>
                                <ul className="btn_toggle">
                                    <li
                                        className="active"
                                        data-index="5"
                                        data-title="monthly"
                                        onClick={this.rate}
                                    >
                                        <span>Monthly</span>
                                    </li>
                                    <li
                                        onClick={this.rate}
                                        data-index="4"
                                        data-title="yearly"
                                    >
                                        <span>Yearly</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <RangeSlider
                            numerRate={this.state.rate}
                            titlePosition={this.state.title}
                            openModal={this.props.openModal}
                        />
                    </div>
                    {/* <div className="block_window video" id="video">
                        <h2 className="block_title">
                            Manage the meetings schedule inside your company,
                            keep all your meetings in one app
                        </h2>
                        <div className="wrap_video">
                            <div className="video">
                                <Video />
                            </div>
                            <div className="video_info">
                                <span className="about">about</span>
                                <span className="duration" >03:07</span>
                            </div>
                        </div>
                    </div>
                */}
                </section>
            </>
        );
    }
}

export default Main;
