import React from "react";

const Icon3 = props => (
  <svg
    id="Icon_03"
    x="0px"
    y="0px"
    viewBox="0 0 119.4 131.5"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st5{fill:none;stroke:#4bb4ff}"}</style>
    <linearGradient
      id="Rounded_Rectangle_1528_1_"
      gradientUnits="userSpaceOnUse"
      x1={3919.0552}
      y1={-6571.5}
      x2={3941.4346}
      y2={-6571.5}
      gradientTransform="matrix(1 0 0 -1 -3919.06 -6496.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_1528"
      d="M12.6 64.4l9.2 9.2c.8.8.8 2 0 2.8l-9.2 9.2c-.8.8-2 .8-2.8 0L.6 76.4c-.8-.8-.8-2 0-2.8l9.2-9.2c.8-.8 2-.8 2.8 0z"
      fill="url(#Rounded_Rectangle_1528_1_)"
    />
    <linearGradient
      id="Rounded_Rectangle_1528_copy_3_1_"
      gradientUnits="userSpaceOnUse"
      x1={3964.3401}
      y1={-6526.2451}
      x2={3986.6899}
      y2={-6526.2451}
      gradientTransform="matrix(1 0 0 -1 -3919.06 -6496.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_1528_copy_3"
      d="M57.8 19.1l9.2 9.2c.8.8.8 2 0 2.8l-9.2 9.2c-.8.8-2 .8-2.8 0l-9.2-9.2c-.8-.8-.8-2 0-2.8l9.2-9.2c.8-.7 2.1-.7 2.8 0z"
      fill="url(#Rounded_Rectangle_1528_copy_3_1_)"
    />
    <linearGradient
      id="Rounded_Rectangle_1528_copy_4_1_"
      gradientUnits="userSpaceOnUse"
      x1={3964.3401}
      y1={-6571.5}
      x2={3986.6899}
      y2={-6571.5}
      gradientTransform="matrix(1 0 0 -1 -3919.06 -6496.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_1528_copy_4"
      d="M57.8 64.4l9.2 9.2c.8.8.8 2 0 2.8l-9.2 9.2c-.8.8-2 .8-2.8 0l-9.2-9.2c-.8-.8-.8-2 0-2.8l9.2-9.2c.8-.8 2.1-.8 2.8 0z"
      fill="url(#Rounded_Rectangle_1528_copy_4_1_)"
    />
    <linearGradient
      id="Rounded_Rectangle_1528_copy_6_1_"
      gradientUnits="userSpaceOnUse"
      x1={3964.3401}
      y1={-6616.7549}
      x2={3986.6899}
      y2={-6616.7549}
      gradientTransform="matrix(1 0 0 -1 -3919.06 -6496.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_1528_copy_6"
      d="M57.8 109.6l9.2 9.2c.8.8.8 2 0 2.8l-9.2 9.2c-.8.8-2 .8-2.8 0l-9.2-9.2c-.8-.8-.8-2 0-2.8l9.2-9.2c.8-.7 2.1-.7 2.8 0z"
      fill="url(#Rounded_Rectangle_1528_copy_6_1_)"
    />
    <linearGradient
      id="Rounded_Rectangle_1528_copy_5_1_"
      gradientUnits="userSpaceOnUse"
      x1={4009.5901}
      y1={-6571.5}
      x2={4031.9695}
      y2={-6571.5}
      gradientTransform="matrix(1 0 0 -1 -3919.06 -6496.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_1528_copy_5"
      d="M103.1 64.4l9.2 9.2c.8.8.8 2 0 2.8l-9.2 9.2c-.8.8-2 .8-2.8 0l-9.2-9.2c-.8-.8-.8-2 0-2.8l9.2-9.2c.8-.8 2-.8 2.8 0z"
      fill="url(#Rounded_Rectangle_1528_copy_5_1_)"
    />
    <path id="Shape_902_copy_2" className="st5" d="M47.2 41.1L22.5 65.8" />
    <path id="Shape_902_copy_3" className="st5" d="M91.8 85.6L67 110.4" />
    <path id="Shape_902_copy_7" className="st5" d="M101.9.5v34" />
    <path id="Shape_902_copy_8" className="st5" d="M118.9 18.5h-34" />
    <path id="Shape_902_copy_4" className="st5" d="M66.3 40.4l24.8 24.8" />
    <path id="Shape_902_copy_5" className="st5" d="M21.8 84.9l24.8 24.8" />
  </svg>
);

export default Icon3;
