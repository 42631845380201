import React from "react";

const Icon6 = props => (
  <svg
    id="Icon_06"
    x="0px"
    y="0px"
    viewBox="0 0 191.5 116"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{fill:none;stroke:#4bb4ff}"}</style>
    <linearGradient
      id="Rectangle_825_copy_19_1_"
      gradientUnits="userSpaceOnUse"
      x1={1084}
      y1={-6545.5}
      x2={1240}
      y2={-6545.5}
      gradientTransform="matrix(1 0 0 -1 -1081.5 -6487.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path
      id="Rectangle_825_copy_19"
      d="M158.5 9.9v96.2c0 4.1-3.3 7.4-7.3 7.4H9.8c-4.1 0-7.3-3.3-7.3-7.4V9.9c0-4.1 3.3-7.4 7.3-7.4h141.4c4 0 7.3 3.3 7.3 7.4z"
      fill="#6439ac"
      stroke="url(#Rectangle_825_copy_19_1_)"
      strokeWidth={5}
    />
    <path className="st1" d="M66.5 7.5v101" />
    <path id="Shape_901_copy" className="st1" d="M155.5 58.5h-89" />
    <linearGradient
      id="Rectangle_825_copy_13_1_"
      gradientUnits="userSpaceOnUse"
      x1={1186.9998}
      y1={-6546}
      x2={1273.0001}
      y2={-6546}
      gradientTransform="matrix(1 0 0 -1 -1081.5 -6487.5)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_825_copy_13"
      d="M191.5 39.6v37.8c0 2.8-2.2 5.1-5.1 5.1h-75.9c-2.8 0-5.1-2.3-5.1-5.1V39.6c0-2.8 2.2-5.1 5.1-5.1h75.9c2.9 0 5.1 2.3 5.1 5.1z"
      fill="url(#Rectangle_825_copy_13_1_)"
    />
    <circle cx={114.5} cy={73.5} r={4} fill="#6439ac" />
  </svg>
);

export default Icon6;
