import React, { Component } from "react";

export class CommonProplems extends Component {
  render() {
    return (
      <div className="common_proplems">
        <div className="first_circle"></div>
        <div className="second_circle"></div>

        <div className="container">
          <div className="left">
            <div className="col">
              <div className="rose_planet">
                <div className="planet"></div>
              </div>
              <p className="col_title">
                Due to unproductive meetings, the companies waste more than
              </p>

              <div className="bottom">
                <h2>$37</h2>
                <p>billion per year</p>
              </div>
            </div>

            <div className="col">
              <div className="rose_planet">
                <div className="planet"></div>
              </div>
              <p className="col_title">
                Just for a single meeting status update, the executives spend an
                average of nearly
              </p>

              <div className="bottom">
                <h2>23</h2>
                <p>
                  hours <br />a month
                </p>
              </div>
            </div>
          </div>
          <div className="right">
            <h3 className="wrap_title">common problems</h3>
            <div className="wrap">
              <ul>
                <li>
                  <h3 className="title">Double booking</h3>
                  <p>
                    Several teams can plan to use a single conference room at
                    once
                  </p>
                </li>

                <li>
                  <h3 className="title">Interruptions </h3>
                  <p>
                    When someone tries to find out if a room occupied or not
                    they open a door and pick in
                  </p>
                </li>

                <li>
                  <h3 className="title">Delays</h3>
                  <p>
                    Your teammates can forget about the planned meeting and come
                    later
                  </p>
                </li>
              </ul>

              <ul>
                <li>
                  <h3 className="title">
                    Inability to check a teammates’ schedule upfront
                  </h3>
                  <p>
                    You should call to your teammates to choose the suitable
                    meeting time for everyone
                  </p>
                </li>

                <li>
                  <h3 className="title">
                    Get lost on the way to a conference room
                  </h3>
                  <p>
                    It is difficult for the guests to navigate in the unknown
                    office
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommonProplems;
