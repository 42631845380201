const today = new Date();
const year = today.getFullYear();
let store = {
    state:{
        links:{
            socialLinks:{
                facebook:"https://www.facebook.com/delibroom/",
                twitter: "https://twitter.com/delibroom",
                linkedin:"https://linkedin.com/company/delibroom"
            },
            support:'support@delibroom.com',
            home: 'https://evnedev.com/'
        },
        footer:{
            copyright: '© ' + year + ' Delibroom',
            author: 'Crafted by EVNE Developers'
        }
    },
    modal:{
        isOpen:false,
        thanks:false
    }
}
window.store = store;
export default store;
