import React from "react";

const Desk = props => (
  <svg
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 727.5 807.6"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1{fill:none;stroke:#8892d1}"}</style>
    <g id="Image-workspace">
      <path
        className="st1"
        d="M257.2 806.6l239.5-406.4L727 805.4M.7 456.6L199 805.4M721.9 379.6H-1.3"
      />
      <path id="Shape_875_copy" className="st1" d="M721.9 398.5H-1.3" />
      <path id="Shape_875_copy_2" className="st1" d="M574.4 535.7H416.7" />
      <path
        id="Rounded_Rectangle_878"
        className="st1"
        d="M82 122.6h328.8c7.2 0 13 5.8 13 13v189c0 7.2-5.8 13-13 13H82c-7.2 0-13-5.8-13-13v-189c0-7.2 5.8-13 13-13z"
      />
      <path
        id="Rounded_Rectangle_878_copy"
        className="st1"
        d="M83.5 137.1H409.3V323.1H83.5z"
      />
      <path className="st1" d="M202.8 336.1h87.3v33.5h-87.3v-33.5z" />
      <path
        id="Rectangle_879_copy"
        className="st1"
        d="M175.2 368.1h143.9v11.6H175.2v-11.6z"
      />
      <circle className="st1" cx={653.3} cy={126.9} r={8.7} />
      <path
        id="Ellipse_885_copy_2"
        className="st1"
        d="M552.9 378.3c0-17.6 20.2-32 45.1-32s45.1 14.3 45.1 32"
      />
      <circle
        id="Ellipse_885_copy"
        className="st1"
        cx={491.9}
        cy={9.2}
        r={8.7}
      />
      <path
        className="st1"
        d="M433.7 39.7l-81.4 46.5h133.8l-52.4-46.5zM432.2 39.7l53.8-32"
      />
      <path id="Shape_888_copy" className="st1" d="M646 122.5L494.8 15" />
      <path id="Shape_888_copy_4" className="st1" d="M651.8 119.6l-155.6-109" />
      <path id="Shape_888_copy_2" className="st1" d="M590.6 344.9l59.7-211.5" />
      <path id="Shape_888_copy_3" className="st1" d="M602.3 344.9l57.1-213.8" />
      <path className="st1" d="M721.9 377.9v18.9" />
      <path id="Shape_875_copy_3" className="st1" d="M46.3 535.7H-.4" />
    </g>
  </svg>
);

export default Desk;
