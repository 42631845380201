import React, { Component } from "react";
import tablet from "../../media/img/h_tablet.png";
import phone from "../../media/img/h_phone.png";
import pointer from "../../media/img/how_it_works_circle.png";

export default class Banner extends Component {
  componentDidMount() {
    setTimeout(() => {
      document.querySelector(".banner").classList.add("active");
    }, 2000);
  }
  render() {
    return (
      <div className="banner slide two">
        <div className="container">
          <div className="present">
            <div>
              <h2 className="present_title one textAnimation">Helps teams</h2>
              <h2 className="present_title two textAnimation">
                to organize meetings
              </h2>
              <h2 className="present_title three textAnimation">
                and work smarter
              </h2>
            </div>
            <div className="pointer">
              <img src={pointer} alt="how it work" />
              <div className="arrow" />
            </div>
          </div>
        </div>
        <div className="wrapDevices">
          <div className="wrapDevices_tablet">
            <img src={tablet} alt="" className="tablet " />
            <div className="device_title">
              <p>
                <b>room monitor</b>
                optional, applied for your comfort
              </p>
            </div>
          </div>
          <div className="wrapDevices_phone">
            <img src={phone} alt="" className="phone" />
            <div className="device_title">
              <p>
                <b>main app</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
