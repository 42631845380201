import React from "react";

const Icon4 = props => (
  <svg
    id="Icon_04"
    x="0px"
    y="0px"
    viewBox="0 0 162 42.6"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st2{fill:none;stroke:#4bb4ff}"}</style>
    <linearGradient
      id="Rectangle_886_copy_3_1_"
      gradientUnits="userSpaceOnUse"
      x1={326.5691}
      y1={-6640.5928}
      x2={294.5381}
      y2={-6603.7446}
      gradientTransform="matrix(1 0 0 -1 -167.844 -6598)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_886_copy_3"
      d="M160.1 42.6c1 0 1.9-.9 1.9-1.9v-.3c-.2-9.2-7.3-16.6-16-16.6-8.8 0-16 7.6-16 16.9 0 1 .8 1.9 1.9 1.9h28.2zm-26.2-3.8c.9-6.3 5.9-11.1 12.1-11.1s11.2 4.9 12.1 11.1h-24.2zm21.2-29.2c-.2-5-4.5-8.9-9.6-8.7-5 .2-8.9 4.5-8.7 9.6.2 4.9 4.3 8.7 9.1 8.7 5.3-.1 9.4-4.4 9.2-9.6zm-14.4 0c.2-2.9 2.8-5.1 5.8-4.9 2.9.2 5.1 2.8 4.9 5.8-.2 2.8-2.5 4.9-5.3 4.9-3.2-.2-5.6-2.8-5.4-5.8z"
      fill="url(#Rectangle_886_copy_3_1_)"
    />
    <linearGradient
      id="Rectangle_886_copy_4_1_"
      gradientUnits="userSpaceOnUse"
      x1={196.5683}
      y1={-6640.5938}
      x2={164.5373}
      y2={-6603.7456}
      gradientTransform="matrix(1 0 0 -1 -167.844 -6598)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_886_copy_4"
      d="M30.1 42.6c1 0 1.9-.9 1.9-1.9v-.3c-.2-9.2-7.3-16.6-16-16.6-8.8 0-16 7.6-16 16.9 0 1 .8 1.9 1.9 1.9h28.2zM3.9 38.8c.9-6.3 6-11.2 12.1-11.2s11.2 4.9 12.1 11.1H3.9v.1zM25.1 9.6c-.2-5-4.5-8.9-9.6-8.7s-8.9 4.5-8.7 9.6c.2 4.9 4.3 8.7 9.1 8.7 5.3-.1 9.4-4.4 9.2-9.6zm-14.4 0c.2-2.9 2.8-5.1 5.8-4.9 2.9.2 5.1 2.8 4.9 5.8-.2 2.8-2.5 4.9-5.3 4.9-3.2-.2-5.6-2.8-5.4-5.8z"
      fill="url(#Rectangle_886_copy_4_1_)"
    />
    <path className="st2" d="M47.2 21h66M97.2 4l18 16-18 19" />
  </svg>
);

export default Icon4;
