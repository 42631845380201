import React from "react";

const InstructionArrRight = props => (
  <svg
    onClick={props.left ? props.left : props.right}
    x="0px"
    y="0px"
    viewBox="0 0 170 101"
    xmlSpace="preserve"
  >
    {props.way === "left" ? (
      <path
        d="M169.5 50.5l-50-50-13 13.2 27.8 28H.5v18h133.7l-27.8 27.7 13 13 50.1-49.9z"
        fill="none"
        className="arrowPrev"
        stroke="#b4b3ff"
      />
    ) : (
      <path
        d="M169.5 50.5l-50-50-13 13.2 27.8 28H.5v18h133.7l-27.8 27.7 13 13 50.1-49.9z"
        fill="none"
        stroke="#b4b3ff"
      />
    )}
  </svg>
);

export default InstructionArrRight;
