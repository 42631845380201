import React, { Component } from "react";
import firebase from "../firebase";

let form = React.createRef();
let field = React.createRef();
let originPass;

class Email extends Component {
    componentDidMount() {
        firebase
            .database()
            .ref("delibroom-pass")
            .on("value", snap => {
                originPass = snap.val();
            });

       
    }
    render() {
        setTimeout(() => {
            field.current.focus();
        }, 1500);
        let sendForm = e => {
            e.preventDefault();

            if (field.current.value == originPass) {
                field.current.classList.remove("error");
                this.props.verification();
                this.props.closeModal();
                this.forceUpdate();
               
            } else {
                field.current.classList.add("error");
            }
        };

        return (
            <>
                <div className="line_pass">
                    <h2 className="title">admin</h2>

                    <form className="form" onSubmit={sendForm} ref={form}>
                        <input
                            type="password"
                            name="password"
                            className="required empty"
                            autoComplete="off"
                            ref={field}
                            onChange={this.props.focus}
                        />
                        <label htmlFor="email">your password</label>
                        <span />
                        <button type="submit" className="btn bright_blue send">
                            enter
                        </button>
                    </form>
                </div>
                <div className="thanks">
                    <div className="figure">
                        <div className="icon">
                            <svg
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                            >
                                <path
                                    d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z"
                                    data-original="#000000"
                                    className="active-path"
                                    data-old_color="#000000"
                                    fill="#25AB85"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="thanks_text">
                        <h2 className="title">
                            Thank You for Interest to Delibroom app!
                        </h2>
                        <h2 className="subtitle">
                            We will contact you as soon as Delibroom app is
                            launched on Android.
                        </h2>
                    </div>
                </div>
            </>
        );
    }
}

export default Email;
