import React, { Component } from "react";
import { NavLink } from "react-router-dom";

export class NotFound extends Component {
    componentDidMount = () => {
        setTimeout(() => {
            this.props.startPresentPage("other_page");
        }, 500);
    }
    
    render() {
        let active = el => {
            setTimeout(() => {
                if (document.querySelector(".not_found")) {
                    document
                        .querySelector(".not_found")
                        .classList.add("active");
                }
            }, 1000);
        };

        return (
            <section className="not_found" id="notFound" onLoad={active()}>
                <div className="left">
                    <h1>This page</h1>
                    <h1>does not exist</h1>
                </div>
                <div className="right">
                    <h2 className="subtitle">
                        You may have misstyped the address or the page has been
                        moved
                    </h2>
                    <div className="line_choose">
                        <NavLink to="/help-center">
                            <button className="btn bright_blue">
                                <span> Help center</span>
                            </button>
                        </NavLink>

                        <NavLink to="/" onClick={this.props.startPreloader}>
                            <button className="btn clear ">
                                <span> Go to homepage</span>
                            </button>
                        </NavLink>
                    </div>
                </div>

                <div className="rose_planet">
                    <div className="planet" />
                </div>

                <div className="blue_planet">
                    <div className="planet" />
                </div>
            </section>
        );
    }
}
export default NotFound;
