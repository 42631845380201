import React, { Component } from "react";
import firebase from "../firebase";


class Waitlist extends Component {
    state = {
        list: [],
        keys: [],
        copySuccess:''
    };

    componentDidMount() {
        firebase.database().ref("list-user-emails").on("value", snap => {
            let a = [];
            let keys = [];
            snap.forEach(function(item) {
                keys.push(item.key);
            });
            snap.forEach(function(item) {
                a.push(item.val());
            });
            this.setState({
                list: a,
                keys: keys
            });
        });
       
    }
    delete = el => {
        let numb = this.state.keys[
            el.currentTarget.closest(".line").getAttribute("id")
        ];
        firebase
            .database()
            .ref("list-user-emails")
            .child(numb)
            .remove();
    };

    render() {
        let status = el => {
            el.classList.add('field_copied');
            setTimeout(() => {
                el.classList.remove('field_copied');
            }, 3000);
        }
        let  copyToClipboard = e => {
            let textField = document.createElement('textarea')
            textField.innerText = e.currentTarget.getAttribute('data-email');
            document.body.appendChild(textField)
            textField.select()
            document.execCommand('copy')
            textField.remove()
            status(e.currentTarget.closest('.line'));
          };

          let choose = e => {
             e.currentTarget.closest('.line').classList.toggle('active');
          }

       
        return (
            <>
                <div className="head_page">
                    <h1 className="block_title">Waitlist</h1>
                    <span className="total">
                        total number: <span>{this.state.list.length}</span>
                    </span>
                </div>
                <div className="user_list">
                    <div className="line caption">
                        <div className="line_date">
                            <div className="date">
                                <span>date</span>
                            </div>

                            <div className="time">
                                <span>time</span>
                            </div>
                        </div>

                        <div className="email">
                            <span>email</span>
                        </div>

                        <div className="options" />
                    </div>


                    {this.state.list.map((item, index) => {
                        return (
                            <div className="line" id={index} key={index} >
                                <div className="line_date" onClick={choose}>
                                    <div className="date">
                                        <span>{item.date}</span>
                                    </div>

                                    <div className="time">
                                        <span>{item.time}</span>
                                    </div>
                                </div>
                                <div className="email" onClick={choose}>
                                    <span>{item.email}</span>
                                </div>

                                <div className="options">
                                    <svg
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        version="1.1"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 488.3 488.3"
                                        data-email={item.email}
                                        onClick={copyToClipboard}
                                        style={{
                                            enableBackground:
                                                "new 0 0 488.3 488.3",
                                            
                                        }}
                                        xmlSpace="preserve"
                                        width="512px"
                                        height="512px"
                                    >
                                        <g transform="matrix(-1 0 0 1 488.3 0)">
                                            <path
                                                d="M314.25 85.4h-227c-21.3 0-38.6 17.3-38.6 38.6v325.7c0 21.3 17.3 38.6 38.6 38.6h227c21.3 0 38.6-17.3 38.6-38.6V124c-.1-21.3-17.4-38.6-38.6-38.6zm11.5 364.2c0 6.4-5.2 11.6-11.6 11.6h-227c-6.4 0-11.6-5.2-11.6-11.6V124c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.6z"
                                                data-original="#000000"
                                                className="active-path"
                                            />
                                            <path
                                                d="M401.05 0h-227c-21.3 0-38.6 17.3-38.6 38.6 0 7.5 6 13.5 13.5 13.5s13.5-6 13.5-13.5c0-6.4 5.2-11.6 11.6-11.6h227c6.4 0 11.6 5.2 11.6 11.6v325.7c0 6.4-5.2 11.6-11.6 11.6-7.5 0-13.5 6-13.5 13.5s6 13.5 13.5 13.5c21.3 0 38.6-17.3 38.6-38.6V38.6c0-21.3-17.3-38.6-38.6-38.6z"
                                                data-original="#000000"
                                                className="active-path"
                                            />
                                        </g>
                                    </svg>
                                    <svg
                                        onClick={this.delete}
                                        version="1.1"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 512.001 512.001"
                                        className="remove"
                                        style={{
                                            enableBackground:
                                                "new 0 0 512.001 512.001"
                                        }}
                                        xmlSpace="preserve"
                                    >
                                        <path d="M294.111 256.001L504.109 46.003c10.523-10.524 10.523-27.586 0-38.109-10.524-10.524-27.587-10.524-38.11 0L256 217.892 46.002 7.894C35.478-2.63 18.416-2.63 7.893 7.894s-10.524 27.586 0 38.109l209.998 209.998L7.893 465.999c-10.524 10.524-10.524 27.586 0 38.109 10.524 10.524 27.586 10.523 38.109 0L256 294.11l209.997 209.998c10.524 10.524 27.587 10.523 38.11 0 10.523-10.524 10.523-27.586 0-38.109L294.111 256.001z" />
                                    </svg>
                                    <b className="copy_status"/>
                                </div>
                               
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
}

export default Waitlist;
