import React, { Component } from "react";

export class Numbers extends Component {
  render() {
    let a = 0;
    if (+this.props.number === 1) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M89.758 84.103V18.854l-19.934 6.682V13.857l32.175-11.792h1.74v82.038H89.758zm-62.049 1.123c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.151 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.66-8.929 35.488-26.785 35.488zm12.803-50.93c0-7.862-1.067-13.467-3.201-16.818-2.134-3.349-5.372-5.025-9.714-5.025-4.267 0-7.478 1.676-9.63 5.025-2.153 3.351-3.229 8.956-3.229 16.818v17.407c0 7.862 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.686 5.25 4.305 0 7.515-1.731 9.63-5.194 2.115-3.463 3.173-9.162 3.173-17.099V34.296z"
        />
      );
    }
    if (+this.props.number === 2) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M121.596 84.103H66.454v-9.658L93.07 45.583c2.92-3.257 5.203-6.055 6.851-8.395 1.647-2.339 2.817-4.445 3.509-6.318.693-1.871 1.039-3.742 1.039-5.615 0-3.818-1.057-6.907-3.172-9.265-2.116-2.358-5.045-3.537-8.788-3.537-4.418 0-7.796 1.357-10.136 4.071-2.339 2.714-3.509 6.317-3.509 10.809H64.882c0-4.829 1.104-9.227 3.313-13.195 2.208-3.968 5.409-7.113 9.602-9.435 4.192-2.32 9.153-3.481 14.881-3.481 5.427 0 10.069.918 13.925 2.752 3.855 1.834 6.795 4.446 8.816 7.833 2.022 3.388 3.032 7.384 3.032 11.989 0 4.792-1.423 9.574-4.267 14.347-2.846 4.773-7.001 10.023-12.466 15.75l-17.744 18.98h37.622v11.23zm-93.887 1.123c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.68-5.559 11.287-8.339 19.822-8.339 8.535 0 15.151 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.66-8.929 35.488-26.785 35.488zm12.803-50.93c0-7.862-1.067-13.467-3.201-16.818-2.134-3.349-5.372-5.025-9.714-5.025-4.268 0-7.478 1.676-9.63 5.025-2.153 3.351-3.229 8.956-3.229 16.818v17.407c0 7.862 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.686 5.25 4.305 0 7.515-1.731 9.63-5.194 2.115-3.463 3.173-9.162 3.173-17.099V34.296z"
        />
      );
    }
    if (+this.props.number === 3) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M105.593 42.101c4.678 1.611 8.141 4.099 10.388 7.468 2.246 3.37 3.369 7.244 3.369 11.624 0 4.979-1.198 9.284-3.594 12.915-2.396 3.631-5.691 6.393-9.883 8.283-4.193 1.889-8.928 2.835-14.206 2.835-4.829 0-9.294-.89-13.393-2.667-4.098-1.778-7.375-4.408-9.827-7.889-2.452-3.482-3.677-7.674-3.677-12.579h13.982c0 2.322.542 4.39 1.628 6.205 1.085 1.816 2.62 3.22 4.605 4.211 1.983.993 4.286 1.489 6.906 1.489 4.23 0 7.533-1.123 9.911-3.369 2.377-2.246 3.566-5.409 3.566-9.49 0-4.493-1.302-7.824-3.903-9.995-2.602-2.171-6.261-3.257-10.978-3.257h-8.142V36.879h8.142c4.455 0 7.842-1.123 10.164-3.369 2.321-2.246 3.481-5.259 3.481-9.041 0-3.818-1.029-6.775-3.088-8.871-2.059-2.097-5.11-3.145-9.153-3.145-2.358 0-4.473.459-6.345 1.376-1.872.917-3.341 2.218-4.408 3.902-1.067 1.685-1.6 3.65-1.6 5.896H65.556c0-4.267 1.123-8.104 3.369-11.511 2.246-3.406 5.362-6.073 9.349-8.002 3.987-1.927 8.452-2.892 13.393-2.892 5.315 0 9.957.88 13.926 2.64 3.967 1.759 7.046 4.379 9.237 7.861 2.19 3.481 3.284 7.73 3.284 12.746 0 3.446-1.086 6.795-3.256 10.052-2.172 3.256-5.26 5.783-9.265 7.58zM27.709 85.226c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.68-5.559 11.287-8.339 19.822-8.339 8.535 0 15.152 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.66-8.929 35.488-26.785 35.488zm12.803-50.93c0-7.862-1.067-13.467-3.201-16.818-2.133-3.349-5.372-5.025-9.714-5.025-4.267 0-7.478 1.676-9.63 5.025-2.153 3.351-3.229 8.956-3.229 16.818v17.407c0 7.862 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.686 5.25 4.305 0 7.515-1.731 9.631-5.194 2.114-3.463 3.172-9.162 3.172-17.099V34.296z"
        />
      );
    }
    if (+this.props.number === 4) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M112.836 65.966v18.137H98.854V65.966H63.759l-.393-8.535L98.461 2.345h14.375v52.391h10.108v11.23h-10.108zM98.854 21.268l-1.01 1.798-19.934 31.67h20.944V21.268zM27.709 85.226c-8.498 0-15.115-2.826-19.85-8.479C3.123 71.095.756 62.092.756 49.738V36.373c0-12.315 2.339-21.253 7.019-26.812 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.152 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.812v13.365c0 23.66-8.928 35.488-26.785 35.488zm12.803-50.93c0-7.861-1.067-13.466-3.201-16.818-2.134-3.349-5.372-5.026-9.714-5.026-4.267 0-7.478 1.677-9.63 5.026-2.153 3.352-3.229 8.957-3.229 16.818v17.407c0 7.861 1.095 13.542 3.285 17.043 2.19 3.5 5.418 5.249 9.686 5.249 4.305 0 7.515-1.73 9.63-5.193 2.115-3.462 3.173-9.162 3.173-17.099V34.296z"
        />
      );
    }
    if (+this.props.number === 5) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M83.805 33.622c1.573-.86 3.454-1.609 5.644-2.246 2.19-.636 4.557-.955 7.103-.955 5.128 0 9.536 1.105 13.224 3.313 3.687 2.21 6.513 5.382 8.479 9.518 1.965 4.137 2.948 9.032 2.948 14.684 0 5.166-1.002 9.809-3.004 13.926-2.003 4.118-5.026 7.375-9.069 9.771-4.043 2.396-9.004 3.593-14.88 3.593-4.68 0-9.004-.907-12.972-2.723-3.967-1.816-7.187-4.473-9.658-7.974-2.471-3.499-3.856-7.701-4.155-12.606h13.589c.486 3.856 1.871 6.832 4.155 8.928 2.283 2.097 5.278 3.145 8.984 3.145 4.268 0 7.515-1.497 9.743-4.493 2.227-2.995 3.341-6.999 3.341-12.016 0-4.791-1.245-8.638-3.734-11.539-2.49-2.901-6.037-4.352-10.641-4.352-1.984 0-3.678.197-5.082.59-1.404.392-2.583.917-3.537 1.571-.955.656-2.013 1.526-3.173 2.611l-11.174-2.807 4.492-41.216h44.136v11.961H85.996l-2.191 19.316zM27.709 85.226c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.152 2.78 19.85 8.339 4.698 5.559 7.047 14.497 7.047 26.813v13.364c0 23.66-8.928 35.488-26.785 35.488zm12.803-50.931c0-7.86-1.067-13.466-3.201-16.817-2.133-3.349-5.372-5.025-9.714-5.025-4.268 0-7.478 1.676-9.63 5.025-2.153 3.351-3.229 8.957-3.229 16.817v17.409c0 7.86 1.095 13.542 3.285 17.041 2.19 3.501 5.419 5.251 9.686 5.251 4.305 0 7.515-1.732 9.63-5.194 2.115-3.463 3.173-9.162 3.173-17.098V34.295z"
        />
      );
    }
    if (+this.props.number === 6) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M118.002 71.553c-2.134 4.249-5.213 7.59-9.237 10.023-4.025 2.434-8.769 3.65-14.235 3.65-5.69 0-10.632-1.45-14.824-4.352-4.193-2.9-7.384-6.794-9.574-11.679-2.19-4.886-3.285-10.248-3.285-16.088V47.66c0-8.609 1.404-16.349 4.212-23.219 2.807-6.869 7.243-12.344 13.308-16.425 6.064-4.079 13.757-6.12 23.079-6.12h1.684v11.512h-.954c-5.616 0-10.352 1.01-14.207 3.032-3.856 2.021-6.823 4.763-8.9 8.226-2.078 3.463-3.379 7.46-3.903 11.988 2.058-2.208 4.511-3.893 7.356-5.054 2.845-1.16 5.952-1.74 9.322-1.74 5.09 0 9.395 1.255 12.914 3.762 3.519 2.509 6.139 5.84 7.862 9.995 1.721 4.156 2.583 8.704 2.583 13.645 0 5.279-1.067 10.042-3.201 14.291zm-12.185-22.292c-1.01-2.489-2.499-4.464-4.464-5.925-1.965-1.459-4.315-2.19-7.047-2.19-3.032 0-5.765.843-8.198 2.527-2.434 1.685-4.175 3.819-5.223 6.402v4.324c0 4.155.599 7.702 1.797 10.64 1.198 2.94 2.817 5.158 4.858 6.654 2.04 1.498 4.314 2.247 6.822 2.247 2.658 0 4.96-.693 6.907-2.078 1.946-1.385 3.444-3.314 4.492-5.784 1.047-2.471 1.572-5.297 1.572-8.479 0-3.069-.505-5.849-1.516-8.338zM27.71 85.226c-8.499 0-15.116-2.826-19.851-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.34-21.254 7.019-26.813 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.151 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.659-8.928 35.488-26.784 35.488zm12.802-50.93c0-7.861-1.067-13.466-3.201-16.818-2.134-3.349-5.372-5.025-9.714-5.025-4.267 0-7.478 1.676-9.63 5.025-2.153 3.352-3.229 8.957-3.229 16.818v17.407c0 7.861 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.687 5.25 4.304 0 7.514-1.731 9.629-5.195 2.115-3.461 3.173-9.161 3.173-17.098V34.296z"
        />
      );
    }
    if (+this.props.number === 7) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M88.522 84.103H73.81l32.512-70.527H64.264V2.345h56.77v7.75L88.522 84.103zm-60.813 1.123c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.152 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.659-8.928 35.488-26.785 35.488zm12.803-50.93c0-7.861-1.067-13.467-3.201-16.818-2.134-3.349-5.372-5.025-9.714-5.025-4.268 0-7.478 1.676-9.63 5.025-2.153 3.351-3.229 8.957-3.229 16.818v17.407c0 7.861 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.686 5.25 4.304 0 7.515-1.731 9.63-5.194 2.115-3.463 3.173-9.162 3.173-17.099V34.296z"
        />
      );
    }
    if (+this.props.number === 8) {
      a = (
        <path
          stroke="#B4B3FF"
          strokeWidth="1px"
          fill="none"
          d="M106.772 41.933c4.118 1.872 7.356 4.539 9.714 8.001 2.358 3.464 3.537 7.422 3.537 11.877 0 4.941-1.16 9.172-3.481 12.69-2.321 3.519-5.512 6.187-9.573 8.002-4.063 1.815-8.676 2.723-13.843 2.723-5.165 0-9.779-.908-13.841-2.723-4.062-1.815-7.254-4.483-9.574-8.002-2.322-3.518-3.481-7.749-3.481-12.69 0-4.493 1.159-8.46 3.481-11.905 2.32-3.443 5.54-6.101 9.659-7.973-3.557-1.834-6.337-4.324-8.339-7.468-2.003-3.145-3.004-6.664-3.004-10.557 0-4.754 1.067-8.834 3.2-12.241 2.134-3.406 5.091-5.999 8.872-7.777 3.781-1.778 8.123-2.668 13.027-2.668 4.905 0 9.246.89 13.028 2.668 3.781 1.778 6.739 4.371 8.872 7.777 2.134 3.407 3.201 7.487 3.201 12.241 0 3.893-1.021 7.412-3.06 10.557-2.042 3.144-4.839 5.634-8.395 7.468zm-5.532-26.168c-2.002-2.208-4.707-3.312-8.114-3.312-3.406 0-6.111 1.076-8.114 3.229-2.003 2.152-3.004 5.062-3.004 8.731 0 3.631 1.001 6.552 3.004 8.76 2.003 2.209 4.708 3.313 8.114 3.313 3.444 0 6.158-1.095 8.143-3.285 1.983-2.19 2.976-5.119 2.976-8.788 0-3.555-1.002-6.438-3.005-8.648zm3.145 38.268c-1.067-2.002-2.583-3.556-4.548-4.66-1.966-1.104-4.24-1.656-6.822-1.656-2.584 0-4.839.552-6.767 1.656-1.928 1.104-3.416 2.658-4.464 4.66-1.049 2.003-1.572 4.334-1.572 6.992 0 4.042 1.151 7.215 3.453 9.517 2.302 2.303 5.456 3.454 9.461 3.454 4.006 0 7.151-1.151 9.434-3.454 2.283-2.302 3.425-5.475 3.425-9.517 0-2.658-.533-4.989-1.6-6.992zM27.709 85.226c-8.498 0-15.114-2.826-19.85-8.479C3.123 71.095.756 62.091.756 49.738V36.374c0-12.316 2.339-21.254 7.019-26.813 4.679-5.559 11.287-8.339 19.822-8.339 8.535 0 15.151 2.78 19.85 8.339 4.697 5.559 7.047 14.497 7.047 26.813v13.364c0 23.659-8.929 35.488-26.785 35.488zm12.803-50.93c0-7.862-1.067-13.467-3.201-16.818-2.134-3.349-5.372-5.025-9.714-5.025-4.267 0-7.478 1.676-9.63 5.025-2.154 3.351-3.229 8.956-3.229 16.818v17.407c0 7.861 1.095 13.543 3.285 17.043 2.19 3.5 5.419 5.25 9.686 5.25 4.305 0 7.515-1.731 9.63-5.194 2.115-3.463 3.173-9.162 3.173-17.099V34.296z"
        />
      );
    }
    return <svg>{a}</svg>;
  }
}
export default Numbers;
