import React, { Component } from "react";
import firebase from "../firebase";

import ReactGA from 'react-ga';
let field = React.createRef();
let btn = React.createRef();

class Email extends Component {
    
    render() {
        let  sendEmailToAnalitics = (el) => {
            let month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
            const rootRef = firebase.database().ref('list-user-emails');
            let date = new Date();
   
            rootRef.push({
                date:` ${date.getDay()} ${month[date.getMonth()]} ${date.getFullYear()}`,
                time:date.toLocaleTimeString(),
                email:el
            }) 
           
             ReactGA.send({
                 category: 'listUserEmails',
                 action: 'send_email'
                 
             });
             ReactGA.event({
                 category: 'listUserEmails',
                 action: 'send_email'
                 
             });
         }
        let attributeSend = () => {
            let field = document.querySelectorAll(".form .empty").length;
            if (field === 0) {
                btn.current.setAttribute("type", "submit");
            } else {
                btn.current.setAttribute("type", "button");
            }
        };

        let error = () => {
            let fields = document.querySelectorAll(".form .empty");
            fields.forEach(function(el) {
                if (el.classList.contains("empty")) {
                    el.classList.add("error");
                } else {
                    el.classList.remove("error");
                }
            });
            attributeSend();
        };

        let send = e => {
            attributeSend();
            error();
        };

        let defrostingEmail = e => {
            let pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;

            if (!pattern.test(e.target.value || e.target.value.length === 0)) {
                e.target.classList.add("empty");
            } else {
                e.target.classList.remove("empty");
                e.target.classList.remove("error");
                e.target.classList.add("focus");
            }

            attributeSend();
            if (e.target.value.trim().length >= 1) {
                e.target.classList.add("focus");
            } else {
                e.target.classList.remove("focus");
            }
        };

        let sendForm = e => {
            e.preventDefault();
            let field = e.target.elements.email.value;
            let pattern = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/;
            if (!pattern.test(field)) {
                e.target.classList.add("empty");
                error();
            } else {
                document.querySelector(".modal_box.active").classList.add("thanks");
                setTimeout(() => {
                    document.querySelector(".modal_box.active").classList.add("show");
                }, 100);
                sendEmailToAnalitics(field)
                e.target.classList.remove("empty");
                e.target.classList.remove("error");
                e.target.classList.add("focus");
            }
        };
        return (
            <>
                <div className="line_email">
                    <h2 className="title">Join the waitlist</h2>
                    <h2 className="subtitle">
                        Enter your details below to join the waitlist. You’ll be
                        the first to know when we launch
                    </h2>
                    <form className="form" onSubmit={sendForm}>
                        <input
                            type="text"
                            name="email"
                            className="required empty"
                            autoComplete="off"
                            onKeyUp={defrostingEmail}
                            ref={field}
                        />
                        <label htmlFor="email">your email</label>
                        <span />
                        <button
                            type="button"
                            className="btn bright_blue send"
                            onClick={send}
                            ref={btn}
                        >
                            submit
                        </button>
                    </form>
                </div>
                <div className="thanks">
                    <div className="figure">
                        <div className="icon">
                            <svg
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                version="1.1"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                                xmlSpace="preserve"
                            >
                                <path
                                    d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z"
                                    data-original="#000000"
                                    className="active-path"
                                    data-old_color="#000000"
                                    fill="#25AB85"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="thanks_text">
                        <h2 className="title">
                            Thank You for Interest to Delibroom app!
                        </h2>
                        <h2 className="subtitle">
                            We will contact you as soon as Delibroom app is
                            launched on Android.
                        </h2>
                    </div>
                </div>
            </>
        );
    }
}

export default Email;
