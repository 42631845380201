import React, { Component } from "react";
import Background from "./background/background";
import Noise from "./background/noise";
import Header from "./header/header";
import Footer from "./footer/footer";
import ModalBox from "./modalBox/modalBox";
import Main from "./main/main";
import HelpCenter from "./helpCenter/helpCenter";
import Contact from "./contact/contact";
import PrivacyPolicy from "./privacyPolicy/privacyPolicy";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Preloader from "./background/preloader";
import NotFound from "./404";
import HiddenPageUserEmails from "./hiddenPageUserEmails";

import ReactGA from "react-ga";

import Responsive from "react-responsive";
import Terms from "./Terms/Terms";
import HowItWork from "./howItWork";
import CookiesAlert from "./CookiesAlert";
ReactGA.initialize("UA-142714674-1");
const Desctop = props => <Responsive {...props} minWidth={1024} />;

class App extends Component {
  state = {
    isOpen: false,
    activeModal: "",
    showPage: false,
    showLink: false,
    classHeader: "",
    showFooter: "",
    footerVisible: ""
  };

  getNameBrowser = () => {
    let ua = navigator.userAgent;
    if (ua.search(/Chrome/) > 0) return "Google Chrome";
    if (ua.search(/Safari/) > 0) return "Safari";
    return "null";
  };

  startPresentPage = el => {
    if (el === "header") {
      setTimeout(() => {
        this.setState({
          classHeader: "focus"
        });
        console.log("main");
      }, 1700);
    } else if (el === "other") {
      this.setState({
        classHeader: "focus other_page"
      });
    } else if (el === "ghost") {
      if (!document.querySelector("header").classList.contains("ghost")) {
        this.setState({
          classHeader: "focus other_page ghost"
        });
      }
    } else if (el === "terms") {
      if (!document.querySelector("header").classList.contains("terms")) {
        this.setState({
          classHeader: " terms"
        });
      }
    } else if (el === "footer") {
      setTimeout(() => {
        this.setState({
          showFooter: "focus"
        });
      }, 920);
    }
  };
  contactPage = el => {
    if (el === "contact_page") {
      this.setState({
        footerVisible: el
      });
    }
  };

  componentDidMount() {
    document.body.classList.add("load");
    setTimeout(() => {
      document.body.classList.remove("load");
    }, 1700);

    let brauser = this.getNameBrowser();
    if (window.innerWidth >= 1025) {
      if (brauser === "Safari") {
        document.querySelector("body").classList.add("safari");
        document.getElementById("root").classList.add("safari");
      } else;

      if (document.querySelector(".slider .slick-current")) {
        document
          .querySelector(".slider .slick-current")
          .classList.remove("slick-active");

        setTimeout(() => {
          document
            .querySelector(".slider .slick-current")
            .classList.add("slick-active");
        }, 1950);
      }
      if (!document.getElementById("root").classList.contains("safari")) {
        document.querySelectorAll(".textAnimation").forEach(el => {
          if (!el.classList.contains("done")) {
            el.classList.add("done");

            let arr = el.innerText.split("");

            let spans = arr.map((item, index) => {
              return `<span key=${index} 
                            style="transition-delay:${index /
                              35}s; -webkit-transition-delay:${index /
                35}s; -moz-transition-delay:${index /
                35}s; -ms-transition-delay:${index / 35}s;">${item}</span>`;
            });
            el.innerHTML = spans.join("");
          }
        });
        document.querySelectorAll(".textAnimation").forEach(el => {
          if (!el.classList.contains("done")) {
            el.classList.add("done");

            let arr = el.innerText.split("");

            let spans = arr.map((item, index) => {
              return `<span key=${index} 
                                style="transition-delay:${index /
                                  35}s; -webkit-transition-delay:${index /
                35}s; -moz-transition-delay:${index /
                35}s; -ms-transition-delay:${index / 35}s;">${item}</span>`;
            });
            el.innerHTML = spans.join("");
          }
        });
      }
    } else;

    localStorage.setItem("impulse", 0);

    document.querySelector("a.to_pricing").addEventListener("click", el => {
      setTimeout(() => {
        if (document.querySelector(".block_window.pricing")) {
          document.querySelector("a.pricingLink").click();
        }
      }, 500);
    });
  }
  fireTracking() {
    ReactGA.pageview(window.location.hash);
  }
  render() {
    let verification = () => {
      this.setState({
        showPage: true
      });
    };
    let openModal = el => {
      document.body.classList.add("hidden");
      if (el === "pass") {
        this.setState({
          isOpen: true,
          activeModal: "pass"
        });
      } else {
        if (el.currentTarget.classList.contains("present")) {
          this.setState({
            isOpen: true,
            activeModal: "present"
          });
        } else if (el.currentTarget.classList.contains("email")) {
          this.setState({
            isOpen: true,
            activeModal: "email"
          });
        } else if (el.currentTarget.classList.contains("jumpToEmail")) {
          document
            .querySelector(".modal_box.active.present")
            .classList.add("jumpToEmail");
        }
      }
    };
    let closeModal = () => {
      document.body.classList.remove("hidden");
      this.setState({
        isOpen: false,
        activeModal: ""
      });
    };
    let links = this.props.store.state.links;
    let footer = {
      footerText: this.props.store.state.footer,
      links: this.props.store.state.links
    };
    window.addEventListener("keydown", function(event) {
      if (event.key === "Escape") {
        if (document.querySelector(".modal_box")) {
          closeModal();
        }
      }
    });
    let startPreloader = () => {
      if (window.innerWidth >= 1025) {
        document.body.classList.add("load");
        setTimeout(() => {
          document.body.classList.remove("load");
        }, 1700);

        if (!document.getElementById("root").classList.contains("safari")) {
          document.querySelectorAll(".textAnimation").forEach(el => {
            if (!el.classList.contains("done")) {
              el.classList.add("done");

              let arr = el.innerText.split("");

              let spans = arr.map((item, index) => {
                return `<span key=${index} 
                                style="transition-delay:${index /
                                  35}s; -webkit-transition-delay:${index /
                  35}s; -moz-transition-delay:${index /
                  35}s; -ms-transition-delay:${index / 35}s;">${item}</span>`;
              });
              el.innerHTML = spans.join("");
            }
          });
        } else;
        if (document.querySelector(".slider .slick-current")) {
          document
            .querySelector(".slider .slick-current")
            .classList.remove("slick-active");

          setTimeout(() => {
            document
              .querySelector(".slider .slick-current")
              .classList.add("slick-active");
          }, 1950);
        } else;
      }
    };
    let ghost = el => {
      if (el.shiftKey && el.altKey) {
        this.setState({
          showLink: true
        });
      }
    };
    let showLink = () => {
      this.setState({
        showLink: true
      });
    };
    let removeLink = () => {
      this.setState({
        showLink: false
      });
    };
    let pageScroll = () => {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    };

    let footerText = this.props.store.state.footer.author;
    return (
      <>
      <BrowserRouter>
        <Desctop>
          <Preloader />
        </Desctop>

        <Noise />
        <Background />
        <Header
          openModal={openModal}
          links={links}
          activeClass={this.state.classHeader}
          showLink={this.state.showLink}
          ghost={ghost}
          removeLink={removeLink}
        />

        <Switch>
          <Route
            path="/"
            exact
            render={() => (
              <Main
                openModal={openModal}
                startPresentPage={this.startPresentPage}
              />
            )}
          />
          <Route
            path="/how-it-work"
            render={() => (
              <HowItWork startPresentPage={this.startPresentPage} />
              // <HelpCenter
              //     openModal={openModal}
              //
              // />
            )}
          />
          <Route
            path="/help-center"
            render={() => (
              <HelpCenter
                openModal={openModal}
                startPresentPage={this.startPresentPage}
              />
            )}
          />

          <Route
            path="/contact"
            render={() => (
              <Contact
                links={links}
                author={footerText}
                startPresentPage={this.startPresentPage}
                contactPage={this.contactPage}
              />
            )}
          />
          <Route
            path="/privacy-policy"
            render={() => (
              <PrivacyPolicy
                openModal={openModal}
                startPresentPage={this.startPresentPage}
              />
            )}
          />
          <Route
            path="/terms-of-use"
            render={() => (
              <Terms
                openModal={openModal}
                startPresentPage={this.startPresentPage}
              />
            )}
          />

          <Route
            path="/user-emails"
            render={() => (
              <HiddenPageUserEmails
                openModal={openModal}
                showPage={this.state.showPage}
                startPresentPage={this.startPresentPage}
              />
            )}
          />

          <Route
            render={() => (
              <NotFound
                startPreloader={startPreloader}
                startPresentPage={this.startPresentPage}
              />
            )}
          />
        </Switch>

        {this.state.isOpen ? (
          <ModalBox
            closeModal={closeModal}
            activeModal={this.state.activeModal}
            openModal={openModal}
            showLink={showLink}
            verification={verification}
          />
        ) : (
          ""
        )}
          <Footer
            footer={footer}
            startPresentPage={this.startPresentPage}
            pageScroll={pageScroll}
            showFooter={this.state.showFooter}
            footerVisible={this.state.footerVisible}
          />
        </BrowserRouter>
        <CookiesAlert />
      </>
    );
  }
}

export default App;
