import React, { Component } from "react";
import col1 from "../../media/img/col_phone1.png";
import col2_i1 from "../../media/img/col_phone2.png";
import col2_i2 from "../../media/img/col_phone3.png";
import col3_i1 from "../../media/img/col_ico3.png";


export class Sheduling extends Component {
  render() {
    return (
      <div className="sheduling">
        <h2 className="block_subtitle">main app</h2>
        <h2 className="block_title">
          Meeting scheduling tool that makes your office more effective
        </h2>

        <div className="wrap">
          <div className="line">
            <div className="col">
              <div className="col_face">
                <img src={col1} alt="" />
              </div>
              <div className="col_title">
                <h2>Recommendation system</h2>
                <h2> will make the best choice for you</h2>
              </div>
            </div>

            <div className="line_description">
              <div className="white_line"></div>
              <p>
                If there are no available rooms according to particular search
                parameters, the system will show some suggestions. They will
                show what parameters should be changed to get the possibility to
                book a room for that period.
              </p>
            </div>
          </div>

          <div className="line couple">
            <div className="col">
              <div className="col_face">
                <img src={col2_i1} alt="" />
                <img src={col2_i2} alt="" />
              </div>
              <div className="col_title">
                <h2>Feel connected to</h2>
                <h2> one another across </h2>
                <h2>remote offices and teams</h2>
              </div>
            </div>

            <div className="line_description">
              <div className="white_line"></div>
              <p>
                The flexibility of Delibroom allows working simultaneously in different offices or multiple companies and switch between them without confusion.
              </p>
            </div>
          </div>

          <div className="line data">
            <div className="col">
              <div className="col_face">
                <img src={col3_i1} alt="" />
              </div>
              <div className="col_title">
                <h2>Your data </h2>
                <h2> stays safe</h2>
              </div>
            </div>

            <div className="line_description">
              <div className="white_line"></div>
              <p>
                Delibroom is built in such a way to be secure and to protect
                your privacy. All data transferred to the remote servers is
                encrypted.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sheduling;
