import React from "react";
import mobile from "../../media/img/Device3.png";
import DownloadLinks from "../downloadLinks/downloadLinks";
import Email from "./email";
const Present = props => {
    return (
        <>
            <div className="line_present">
                <div className="line_upper">
                    <div className="line_left">
                        <div className="line_col">
                            <span className="number">1</span>
                            <div className="upper">
                                <div>
                                    <h2 className="title">
                                        Download the app for mobile
                                        <span className="optional" />
                                    </h2>
                                    <p className="subtitle">
                                        On iOS and Android avaliable for free
                                    </p>
                                </div>
                            </div>
                            <div className="bottom">
                                <DownloadLinks
                                    openModal={props.openModal}
                                    device="tel"
                                    otherClass={"jumpToEmail"}
                                />
                            </div>
                        </div>
                        <div className="line_col">
                            <span className="number">2</span>
                            <div className="upper">
                                <div>
                                    <h2 className="title">
                                        Download the app for tablet
                                        <span className="optional ">
                                            (optional)
                                        </span>
                                    </h2>
                                    <p className="subtitle">
                                        On iOS and Android avaliable for free
                                    </p>
                                </div>
                            </div>
                            <div className="bottom">
                                <DownloadLinks
                                    openModal={props.openModal}
                                    device="tablet"
                                    otherClass={"jumpToEmail"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="line_right">
                        <div className="line_col">
                            <span className="number">3</span>
                            <div className="upper">
                                <div>
                                    <h2 className="title">
                                        Register via mobile phone
                                    </h2>
                                    <p className="subtitle">
                                        AvaPair tablet with meeting room
                                        (optional)
                                    </p>
                                </div>
                            </div>
                            <img src={mobile} alt="" className="device" />
                        </div>
                    </div>
                </div>
                <div className="line_bottom" onClick={props.closeModal}>
                    <span>proceed to the web site</span>
                </div>
            </div>
            <Email />
        </>
    );
};

export default Present;
