import React, { Component } from "react";
// import { NavLink } from "react-router-dom";
import AnyQuestions from "../anyQuestions/anyQuestions";

class Terms extends Component {
    componentDidMount() {
        this.props.startPresentPage("other");
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }

    render() {
        return (
            <>
                <section className="main_content wrapper_privacy_policy">
                    <div className="block_window privacy_policy">
                        <h1 className="block_title">
                            Terms and Conditions of Service
                        </h1>

                        <div className="wrapper_privacy_policy">
                            <div className="line">
                                <span className="text">
                                    Welcome to our Delibroom system - the site
                                    delibroom.com and the application
                                    (hereinafter, the Application). These are
                                    our Terms and Conditions of Service
                                    (hereinafter, Terms and Conditions) for our
                                    applications (both phone and tablet). You
                                    can reach them by downloading the
                                    applications at Apple Store (the Application
                                    is subject to the Apple Store terms and
                                    conditions respectively). In each situation
                                    further talking about the terms of service,
                                    we mean the Delibroom service operated
                                    personally or on the behalf. These Terms and
                                    Conditions apply to any user, visitor,
                                    owner, admin, and others who access the
                                    Application.
                                </span>
                            </div>
                            <div className="line">
                                <span className="text ">
                                    By using the Delibroom system, you agree to
                                    these conditions. Please read them
                                    carefully. Without approval, you are not
                                    allowed to use the Application or
                                    corresponding site.
                                </span>
                            </div>

                            <div className="line" id="what_information">
                                <h2 className="pp_title">Privacy</h2>
                                <span>
                                    Please read our Privacy and Cookie Policy to
                                    understand our attitude to the privacy of
                                    personal information:
                                    https://delibroom.com/privacy-policy
                                </span>
                            </div>
                            <div className="line" id="what_purpose">
                                <h2 className="pp_title ">
                                    Electronic Communications
                                </h2>
                                <span>
                                    When you use the Application or send
                                    e-mails, text messages, and other
                                    communications from your desktop or mobile
                                    device to us, you may be communicating with
                                    us electronically. You consent to receive
                                    communications from us electronically, such
                                    as e-mails, texts, or notices and messages.
                                    You have the right to retain copies of these
                                    communications for your records.
                                </span>
                            </div>
                            <div className="line" id="company_ensures">
                                <h2 className="pp_title ">Copyright</h2>
                                <span>
                                    All content available via the Application,
                                    such as records, texts, graphics, images,
                                    logos, and software is the property of EVNE
                                    Developers and protected by international
                                    copyright laws.
                                </span>
                            </div>
                            <div className="line" id="in_what">
                                <h2 className="pp_title">Services provision</h2>
                                <span>
                                    We endeavor to provide the services 24 hours
                                    a day 7 days a week, however, from time to
                                    time, the Application may be unavailable due
                                    to scheduled or unscheduled maintenance.
                                    Under such maintenance, access to the
                                    Application may be limited. We endeavor to
                                    perform such maintenance to non-business
                                    hours and give reasonable written notice
                                    about such interruption. The same goes for
                                    the third party services accessibility,
                                    including but not limited to the Apple
                                    Store. We may offer automatic updates to the
                                    Application downloaded from the Store at any
                                    time and without notice to you beforehand.
                                </span>
                                <span>
                                    You should be solely responsible for the
                                    availability of Internet connection, the
                                    speed of which can influence the
                                    serviceability of the Application. You
                                    should not use the Application to transmit,
                                    store or publish any content that is
                                    obscene, libelous, threatening or unlawful
                                    or that infringes or violates any rights.
                                    According to these Terms and Conditions, we
                                    may impose limits on the use of the
                                    Application if such violations were
                                    revealed. We reserve the right to postpone
                                    the provision of services if the Application
                                    was used in a malicious way (unauthorized
                                    use, disruption or abuse of resources, and
                                    similar events). You may not copy, modify,
                                    distribute, sell, or lease any part of the
                                    Delibroom services or included software, nor
                                    may you reverse engineer or attempt to
                                    extract the source code of that software,
                                    unless laws prohibit those restrictions or
                                    you have our written permission.
                                </span>
                                <span>
                                    If you believe we breached either of such
                                    obligations, you may give us written notice
                                    at support@delibroom.com , and we will
                                    remedy the problem as soon as is practically
                                    possible
                                </span>
                            </div>
                            <div className="line" id="personal_information">
                                <h2 className="pp_title">Termination of use</h2>
                                <span>
                                    If you do not want to use the Application,
                                    you may send us a letter at{" "}
                                    support@delibroom.com and your account will
                                    be manually deleted. The automatic deletion
                                    of the accounts is described in the Privacy
                                    and Cookie Policy. If you no longer accept
                                    these Terms and Conditions or future
                                    modifications of these Terms and Conditions
                                    you must cease using the Application and
                                    corresponding site. If for any reason, some
                                    of them are mentioned but not limited in the
                                    above, we believe that you have failed to
                                    comply with these Terms and Conditions, we
                                    may, at our sole discretion, cancel access
                                    to the Application immediately and without
                                    prior notice.
                                </span>
                            </div>
                            <div className="line" id="cookie">
                                <h2 className="pp_title">Disclaimer</h2>
                                <span>
                                    The Delibroom services and all information,
                                    content, materials, products (including
                                    software) and other services included on or
                                    otherwise made available to you through the
                                    Application are provided on “as is” and “as
                                    available” basis, unless otherwise specified
                                    in writing. We make no representations or
                                    warranties of any kind, express or implied,
                                    as to the operation of the Delibroom
                                    services, unless otherwise specified in
                                    writing. You expressly agree that your use
                                    of the Delibroom services is at your sole
                                    risk.
                                </span>
                                <span>
                                    To the full extent permissible by law, we
                                    disclaim all warranties, express or implied,
                                    including, but not limited to fitness for a
                                    particular purpose. We do not warrant that
                                    the Delibroom services, information,
                                    content, materials, products (including
                                    software) or other services included on or
                                    otherwise made available to you through the
                                    Delibroom services, servers or electronic
                                    communications sent from us are free of
                                    viruses or other harmful components. To the
                                    full extent permissible by law, we will not
                                    be liable for any damages of any kind
                                    arising from the use of any Delbroom
                                    service, or from any information, content,
                                    materials, products (including software) or
                                    other services included on or otherwise made
                                    available to you through any Delibroom
                                    service, including, but not limited to
                                    direct, indirect, incidental, and
                                    consequential damages, unless otherwise
                                    specified in writing.
                                </span>
                                <span>
                                    Without limiting the foregoing, we are not
                                    responsible for any third-party content,
                                    information, web sites, software, services
                                    or other materials of third parties with
                                    which you may interact in connection with
                                    use of the Delibroom system, and we shall
                                    have no liability whatsoever arising from or
                                    related to use of other services.
                                </span>
                            </div>
                            <div className="line">
                                <span>
                                    Changes to these Terms and Conditions may be
                                    applied without prior written notice. In
                                    case if the terms and Conditions are
                                    changed, the special entry is made below for
                                    everyone to track such alterations.
                                </span>
                            </div>
                            <div className="line">
                                <h2 className="pp_title">Latest changes</h2>
                                <span>
                                    May 31, 2019 - Initial draft published.
                                </span>
                            </div>
                        </div>

                        <AnyQuestions openModal={this.props.openModal} />
                    </div>
                </section>
            </>
        );
    }
}

export default Terms;
