import React from "react";

const SvgComponent = props => (
  <svg
    id="Icon_08"
    x="0px"
    y="0px"
    viewBox="0 0 131.2 163"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st1,.st2{fill:#6439ac;stroke:#4bb4ff}.st2{fill:none}"}</style>
    <linearGradient
      id="Rounded_Rectangle_880_1_"
      gradientUnits="userSpaceOnUse"
      x1={2050}
      y1={-6500.5}
      x2={2168}
      y2={-6500.5}
      gradientTransform="matrix(1 0 0 -1 -2039.25 -6424.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_880"
      d="M57.8 2.5h64c3.9 0 7 3.1 7 7h0l-40 133c0 3.9-3.1 7-7 7h0-64c-3.9 0-7-3.1-7-7h0l40-133c0-3.9 3.1-7 7-7h0z"
      fill="#6439ac"
      stroke="url(#Rounded_Rectangle_880_1_)"
      strokeWidth={5}
    />
    <path
      className="st1"
      d="M9.6 137.8C5 131.5-5.5 106.5 7.8 97.5s26.4-5.6 26.4-5.6 11.7 9.9-16 17.2"
    />
    <path className="st2" d="M97.8 37.5l-2 8h-46l6-18h55l-3 10h-10z" />
    <path
      className="st1"
      d="M101.8 162.1c22.6-51.1 23.4-91.4 23.4-91.4.8-9.9 1.1-19.8.8-29.6-3-25-18-22.4-12.2-2.2 1.8 7.7 1.2 15.8-1.8 23.1-.2.8-1.8-9.8-9.9-20-7-13.7-13.7-2.4-5.1 11.3 2.7 10.6 13.7 24 9.6 31.7-4.5 5.3-8.3-12.6-14.8-20.4-3.4-8.8-11.9-1.7-5.1 11.3C94.9 90.4 98 122.3 98 122.3"
    />
    <linearGradient
      id="Rounded_Rectangle_881_1_"
      gradientUnits="userSpaceOnUse"
      x1={73.75}
      y1={159}
      x2={106.75}
      y2={159}
      gradientTransform="matrix(1 0 0 -1 0 166)"
    >
      <stop offset={0} stopColor="#9c6bd1" />
      <stop offset={1} stopColor="#6999ee" />
    </linearGradient>
    <path
      id="Rounded_Rectangle_881"
      d="M78.2 2.5h24c2.5 0 4.5 2 4.5 4.5s-2 4.5-4.5 4.5h-24c-2.5 0-4.5-2-4.5-4.5.1-2.5 2.1-4.5 4.5-4.5z"
      fill="url(#Rounded_Rectangle_881_1_)"
    />
    <path className="st2" d="M26.8 124.5l-3 11h55l3-11h-55z" />
    <path
      id="Shape_903_copy"
      className="st2"
      d="M59.8 12.5l-3 11h11l3-11h-11z"
    />
    <path
      id="Shape_903_copy_3"
      className="st2"
      d="M106.8 12.5l-3 11h11l3-11h-11z"
    />
    <path
      id="Shape_903_copy_2"
      className="st2"
      d="M75.8 16.5l-1 5h24l1-5h-24z"
    />
    <path className="st2" d="M31.8 112.5h53" />
    <path id="Shape_904_copy" className="st2" d="M35.8 100.5h53" />
    <path id="Shape_904_copy_2" className="st2" d="M39.8 88.5h51" />
  </svg>
);

export default SvgComponent;
