import React from "react";
const Benefits = () => {
    return (
        <div className="block_window feature " id="feature">
            <h2 className="block_title">Benefits for You</h2>
            <div className="line_benefits">
                <div className="line_benefits_icon">
                    <div className="core">
                        <span className="number">01</span>
                        <svg
                            className="line_benefits_icon_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width={65}
                            height={64}
                            viewBox="0 0 65 64"
                        >
                            <defs>
                                <linearGradient
                                    id="linear-gradient"
                                    x1="7079.81"
                                    y1="1871.92"
                                    x2="7115.88"
                                    y2="1871.92"
                                    gradientUnits="userSpaceOnUse"
                                >
                                    <stop offset={0} stopColor="#4bb4ff" />
                                    <stop offset={1} stopColor="#ee1e8f" />
                                </linearGradient>
                            </defs>
                            <path
                                className="cls-1"
                                d="M7098.42,1904L7067,1887.67v-30.34l31.42-16.33,32.58,16.33v30.34Z"
                                transform="translate(-7066.5 -1840.5)"
                            />
                            <path
                                className="cls-1"
                                d="M7067,1886.5l13.96-5.83"
                                transform="translate(-7066.5 -1840.5)"
                            />
                            <path
                                id="Shape_842_copy_2"
                                data-name="Shape 842 copy 2"
                                className="cls-1"
                                d="M7114.71,1863.17l13.96-5.84"
                                transform="translate(-7066.5 -1840.5)"
                            />
                            <path
                                id="Shape_842_copy"
                                data-name="Shape 842 copy"
                                className="cls-1"
                                d="M7128.67,1887.8l-14.2-5.97"
                                transform="translate(-7066.5 -1840.5)"
                            />
                            <path
                                id="Shape_842_copy_3"
                                data-name="Shape 842 copy 3"
                                className="cls-1"
                                d="M7082.13,1864.47l-14.21-5.97"
                                transform="translate(-7066.5 -1840.5)"
                            />
                            <path
                                className="cls-2"
                                d="M7082.13,1863.17l16.29-9.34,16.29,9.34-16.29,8.16Zm-2.33,2.33,17.45,8.17V1890l-17.45-9.33V1865.5Zm19.78,8.17,16.29-8.17v15.17l-16.29,9.33v-16.33Z"
                                transform="translate(-7066.5 -1840.5)"
                            />
                        </svg>
                    </div>
                </div>
                <div className="line_benefits_title">
                    <h3>Completely independent solution</h3>
                </div>
                <div className="line_benefits_description">
                    <div className="white_line" />
                    <p>
                        All data is stored on a remote server. You are not tied
                        to devices and infrastructure.
                    </p>
                </div>
            </div>
            <div className="line_benefits">
                <div className="line_benefits_icon">
                    <div className="core">
                        <span className="number">02</span>
                        <svg
                            className="line_benefits_icon_2"
                            version="1.1"
                            id="Icon_2"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="56px"
                            height="62px"
                            viewBox="0 0 56 62"
                            style={{
                                enableBackground: "new 0 0 56 62"
                            }}
                            xmlSpace="preserve"
                        >
                            <path
                                className="st0"
                                d="M6,22v34h29V20H5L19,6h31v36L37,55"
                            />
                            <path className="st0" d="M7,55l13-13V8" />
                            <path className="st0" d="M22,42h27" />
                            <path className="st0" d="M36,20L50,6" />
                            <linearGradient
                                id="Rectangle_834_copy_7_1_"
                                gradientUnits="userSpaceOnUse"
                                x1="6484.5298"
                                y1="-488.501"
                                x2="6540.5098"
                                y2="-488.501"
                                gradientTransform="matrix(1 0 0 -1 -6484.5298 -457.526)"
                            >
                                <stop
                                    offset={0}
                                    style={{ stopColor: "#4BB4FF" }}
                                />
                                <stop
                                    offset={1}
                                    style={{ stopColor: "#EE1E8F" }}
                                />
                            </linearGradient>
                            <path
                                id="Rectangle_834_copy_7"
                                className="st1"
                                d="M42,56l-6.1,6l-6-6l6-6L42,56z M56,42.1l-6.1,6l-6-6l6-6L56,42.1z M56,6l-6.1,6l-6-6l6-6L56,6z M12.1,56l-6,6L0,56l6.1-6L12.1,56z M12.1,19.9l-6,6l-6.1-6l6.1-6L12.1,19.9z M42,19.9l-6.1,6l-6-6l6-6L42,19.9zM26.2,42.1l-6.1,6l-6-6l6-6L26.2,42.1z M26.1,6l-6,6L14,6l6.1-6L26.1,6z"
                            />
                        </svg>
                    </div>
                </div>
                <div className="line_benefits_title">
                    <h3>Flexible and easy management</h3>
                </div>
                <div className="line_benefits_description">
                    <div className="white_line" />
                    <p>
                        Ability to work simultaneously in different companies
                        (and easily switch between them), the possibility of
                        having several offices in one company, the presence of
                        meeting rooms with different infrastructure.
                    </p>
                </div>
            </div>
            <div className="line_benefits">
                <div className="line_benefits_icon">
                    <div className="core">
                        <span className="number">03</span>
                        <svg
                            className="line_benefits_icon_3"
                            version="1.1"
                            id="Icon_3"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="60px"
                            height="50px"
                            viewBox="0 0 60 50"
                            style={{
                                enableBackground: "new 0 0 60 50"
                            }}
                            xmlSpace="preserve"
                        >
                            <g id="Calendar">
                                <path
                                    id="Rectangle_815_copy_7"
                                    className="st0"
                                    d="M23,20h14v10H23V20z M40,20h14v10H40V20z M40,33h14v10H40V33z M6,33h14v10H6V33z"
                                />
                                <linearGradient
                                    id="Ellipse_7_copy_5_1_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="6483.5298"
                                    y1="-687.5"
                                    x2="6543.5298"
                                    y2="-687.5"
                                    gradientTransform="matrix(1 0 0 -1 -6483.5298 -662.5)"
                                >
                                    <stop
                                        offset={0}
                                        style={{
                                            stopColor: "#4BB4FF"
                                        }}
                                    />
                                    <stop
                                        offset={1}
                                        style={{
                                            stopColor: "#EE1E8F"
                                        }}
                                    />
                                </linearGradient>
                                <path
                                    id="Ellipse_7_copy_5"
                                    className="st1"
                                    d="M11.1,0C10,0,8,0.3,8,1v6h5V1C13,0.2,12.2,0,11.1,0z M50.1,0C49,0,47,0.3,47,1v6h5V1C52,0.2,51.2,0,50.1,0z M0,7h58v10H0V7z M0,47h58v3H0V47z M57,7h3v43h-3V7z M0,7h3v42H0V7z M6,20v10h14V20H6z M23,33v10h14V33H23z"
                                />
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="line_benefits_title">
                    <h3>One place to control all your meetings</h3>
                </div>
                <div className="line_benefits_description">
                    <div className="white_line" />
                    <p>
                        Built-in meeting planner allows to plan and control all
                        your meetings for months.
                    </p>
                </div>
            </div>
            <div className="line_benefits">
                <div className="line_benefits_icon">
                    <div className="core">
                        <span className="number">04</span>
                        <svg
                            className="line_benefits_icon_4"
                            version="1.1"
                            id="Icon_4"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            x="0px"
                            y="0px"
                            width="68px"
                            height="68px"
                            viewBox="0 0 68 68"
                            style={{
                                enableBackground: "new 0 0 68 68"
                            }}
                            xmlSpace="preserve"
                        >
                            <g id="Calendar">
                                <g>
                                    <linearGradient
                                        id="Ellipse_7_copy_5_1_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6483.5298"
                                        y1="-878.5"
                                        x2="6551.5298"
                                        y2="-878.5"
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path
                                        id="Ellipse_7_copy_5"
                                        className="st0"
                                        d="M34,68C15.2,68,0,52.8,0,34C0,15.2,15.2,0,34,0c18.8,0,34,15.2,34,34C68,52.8,52.8,68,34,68C34,68,34,68,34,68z M34,4.8C17.9,4.8,4.8,17.9,4.8,34c0,16.1,13.1,29.2,29.2,29.2c16.1,0,29.2-13.1,29.2-29.2l0,0C63.2,17.9,50.1,4.8,34,4.8z M65.2,32.7v2.5 M3,35.3v-2.5"
                                    />
                                    <linearGradient
                                        id="Ellipse_7_copy_6_1_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6512.9995"
                                        y1="-887.4699"
                                        x2="6540.0474"
                                        y2="-887.4699"
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path
                                        id="Ellipse_7_copy_6"
                                        className="st1"
                                        d="M36.4,32.5l-2.6-2.6c-0.6-0.6-1.6-0.6-2.2,0c0,0,0,0,0,0l0,0l-1.7,1.7c-0.6,0.6-0.6,1.6,0,2.2l0,0l2.6,2.6L36.4,32.5L36.4,32.5L36.4,32.5z M54.4,56l1.7-1.7c0.6-0.6,0.6-1.6,0-2.2c0,0,0,0,0,0v0L37.5,33.5l0,0l-3.9,3.9L52.2,56C52.8,56.7,53.8,56.7,54.4,56C54.4,56.1,54.4,56.1,54.4,56L54.4,56z"
                                    />
                                    <linearGradient
                                        id="SVGID_1_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6512.5298"
                                        y1="-895.5"
                                        x2="6515.5298"
                                        y2="-895.5"
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path className="st2" d="M29,56l3-10" />
                                    <linearGradient
                                        id="SVGID_2_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6521.5298"
                                        y1={-863}
                                        x2="6524.5298"
                                        y2={-863}
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path className="st3" d="M41,13l-3,11" />
                                    <linearGradient
                                        id="SVGID_3_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6503.5298"
                                        y1={-868}
                                        x2="6510.5298"
                                        y2={-868}
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path className="st4" d="M20,20l7,7" />
                                    <linearGradient
                                        id="SVGID_4_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6496.5298"
                                        y1={-884}
                                        x2="6506.5298"
                                        y2={-884}
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path className="st5" d="M13,41l10-3" />
                                    <linearGradient
                                        id="SVGID_5_"
                                        gradientUnits="userSpaceOnUse"
                                        x1="6529.5298"
                                        y1={-875}
                                        x2="6540.5298"
                                        y2={-875}
                                        gradientTransform="matrix(1 0 0 -1 -6483.5298 -844.5)"
                                    >
                                        <stop
                                            offset={0}
                                            style={{
                                                stopColor: "#4BB4FF"
                                            }}
                                        />
                                        <stop
                                            offset={1}
                                            style={{
                                                stopColor: "#EE1E8F"
                                            }}
                                        />
                                    </linearGradient>
                                    <path className="st6" d="M46,32l11-3" />
                                </g>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="line_benefits_title">
                    <h3>Multi-directional usage</h3>
                </div>
                <div className="line_benefits_description">
                    <div className="white_line" />
                    <p>
                        One can see the status of the conference room, book a
                        room on the go, view the schedule of this room. For the
                        person who created a meeting there is an opportunity to
                        change its parameters: finish earlier, or, if the
                        schedule allows, extend it. Whatever the situation is.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Benefits;