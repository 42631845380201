import React, { Component } from "react";
import AnyQuestions from "../anyQuestions/anyQuestions";
import { Link } from "react-scroll";

class PrivacyPolice extends Component {
    componentDidMount() {
        this.props.startPresentPage("other");
    }
    render() {
       
        return (
            <>
                <section className="main_content wrapper_privacy_policy">
                    <div className="block_window privacy_policy">
                        <h1 className="block_title">
                            Privacy and cookie policy
                        </h1>

                        <div className="wrapper_privacy_policy">
                            <div className="line">
                                <span className="text">
                                    We respect your data and take all pains to care about it. This Privacy Policy
                                    explains who we are, how we collect, share and use personal information, received
                                    from our users, and how you can exercise your privacy rights.
                                </span>
                                <span className="text">
                                    EVNE Developers LLC (hereinafter the &ldquo;Company&rdquo;), creators, owners and
                                    operators of the site https://delibroom.com/ (hereinafter the &ldquo;Site&rdquo;)
                                    and corresponding application Delibroom (hereinafter the &ldquo;Application&rdquo;)
                                    provides you (hereinafter the &ldquo;User&rdquo;) the information including but not
                                    limited to the following:
                                </span>

                                <ul>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="what_information"
                                        >
                                            What information about the User is
                                            collected.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="what_purpose"
                                        >
                                            For what purpose the Company
                                            collects personal information.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="company_ensures"
                                        >
                                            How the Company ensures security of
                                            the data.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="third_parties"
                                        >
                                            In what case the personal
                                            information is transferred to third
                                            party.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="personal_information"
                                        >
                                            How the personal information can be
                                            updated or deleted.
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            activeClass="active "
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                            to="cookie"
                                        >
                                            Cookie policy.
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="line ">
                                <span className="italic">
                                    Any use of personal data of the User is carried out in accordance with Act
                                    No. 101/2000 Coll. on the Protection of Personal Data, the General Data Protection
                                    Regulation (EU) 2016/679 (GDPR) and other laws and regulations applicable in Ukraine.
                                </span>

                                <span>
                                    By using the Site and the Application, the User agrees to this Privacy and Cookie
                                    Policy. Some types of the data collection and uses described in this Policy are
                                    required in order the User could use the Application. Some types of the data
                                    collection described in this Policy are optional and can be used for improvement
                                    of the Site and Application. If the User does not agree with the stated issues,
                                    they may not use the Application.
                                </span>
                            </div>

                            <div className="line" id="what_information">
                                <h2 className="pp_title">
                                    What information about the User is collected
                                </h2>
                                <span>
                                    Personal data or personal information means any information about an individual
                                    from which that person can be identified.
                                </span>
                                <span>
                                    The information we collect is rather limited due to the sphere of our services.
                                    We do not have to collect big amounts of information to provide the services to
                                    the Application users. Such collection happens only with the registration of other
                                    companys’ team members in the system.
                                </span>
                                <span>
                                    The personal information range may include the following business contact
                                    information (such as your name and surname, organization, email address), marketing
                                    information (your preferred channels of communication for the support issues)
                                    submitted by the user with our contact form or help center.
                                </span>
                                <span>
                                    As it is necessary to store the data about the User subscription to be able to
                                    provide access to the Application, the information associated with each account is
                                    to be preserved until such account is deleted (or after 1 year from the date of
                                    termination of the Application subscription), with the exception of information
                                    that has to be archived (e.g. for tax purposes or any other legal obligations).
                                </span>
                                <span>
                                    Another section of privacy data we may have - is automatically collected
                                    information. First of all, the additional information goes for cookie files and
                                    similar technologies. You can discover the ways we manage them in our Cookie
                                    Policy section.
                                </span>
                                <span>
                                    Additionally, the Site has Google Analytics to analyze the tendencies of users’
                                    visits and their browsing activities. Google has its corresponding Privacy Policy
                                    and all information on the collected data is listed
                                    <a href="https://policies.google.com/privacy?hl=en-US" target="_blank">here</a>.
                                </span>
                                <span>
                                    The information collected with this service is mostly statistical for us to analyse
                                    the traffic and improve the content  of the website.
                                </span>
                                <span>
                                    The Application has the corresponding analytical services such as: Firebase
                                    Analytics, Sentry, Facebook SDK, AppStore Analytics to analyse the users behavior.
                                    The data collected is solely statistical and can not reveal the identity of the
                                    User. This information is used to improve the experience of the users within the
                                    Application and correct technical issues that may arise.
                                </span>
                                <h2 className="pp_title">
                                    The information we collect automatically includes:
                                </h2>
                                <span>
                                    Device information for website: such as your IP address, your browser, operating
                                    system, device information, unique device identifiers, mobile network information,
                                    request information (speed, frequency, the site from which you linked to us
                                    (&ldquo;referring page&rdquo;), the name of the website you choose to visit
                                    immediately after ours (called &ldquo;exit page&rdquo;), the web browser you used
                                    (software used to browse the internet) including its type and language), and
                                    viewfinder size and scripts errors. Device information for apps: your country,
                                    device information, unique device identifier, screens and events, interactions with
                                    the target buttons, the name of the source you come from to the app, the device
                                    type and model you use with the app, engagement of the users within the apps.
                                </span>
                                <span>
                                    Usage data for website and apps: such as information about how you interact with
                                    our services (such as the pages and files viewed, session duration, links clicked,
                                    searches, non-sensitive text entered, operating system and system configuration
                                    information and date/time stamps associated with your usage).
                                </span>
                                <span>
                                    The Company <b>does not</b> collect any information about credit cards and payment
                                    services you may use. The Company <b>does not</b> collect any special categories of
                                    personal information (e.g. politic opinion, religion, information about
                                    health, etc.). The Company <b>does not</b> collect any information from the
                                    individuals under 18. The persons under 18 may not submit any personal data and
                                    use the Application. If a person believes that their children and minors submitted
                                    some personal information to the Company, they should inform the Company at
                                    <a href="mailto:info@delibroom.com">info@delibroom.com</a> and such information
                                    will be deleted.
                                </span>
                            </div>
                            <div className="line" id="what_purpose">
                                <h2 className="pp_title ">
                                    For what purpose the Company collects personal information
                                </h2>
                                <span>
                                    The Company may use personal information to provide, maintain, protect, and improve
                                    the Site and Applications. Personal information can be used for additional purposes
                                    that do not directly relate to provision of the service itself including, but not
                                    limited to the development of new features of the Application, marketing analytics
                                    and researches, etc.<br />
                                    Please note that the subscription option and submission of corresponding personal
                                    information is necessary to use the Application.<br />
                                    Specifically, we collect information for a range of reasons in reliance on our
                                    legitimate interests, including:
                                </span>
                                <ul>
                                    <li>
                                        To provide, operate, optimize, and maintain the Site and the Applications.
                                    </li>
                                    <li>
                                        To allow you use our services in full after the creation of account in the
                                        system of apps.
                                    </li>
                                    <li>
                                        To send you transactional communications (e-mail), about the events that arise
                                        in our services after the actions of users.
                                    </li>
                                    <li>
                                        To respond to your online inquiries and requests, and to provide you with
                                        information and access to resources or services that you have requested from
                                        us with the support senter or email request.
                                    </li>
                                    <li>
                                        To improve the navigation and content of the Site and the Application.
                                    </li>
                                    <li>
                                        To identify any server problems or other IT or network issues.
                                    </li>
                                    <li>
                                        To compile aggregated statistics about site and apps usage and to better
                                        understand the preferences and actions of our visitors and users.
                                    </li>
                                    <li>
                                        To carry out other legitimate business purposes, as well as other lawful
                                        purposes, such as data analysis, fraud monitoring and prevention, identifying
                                        usage trends and expanding our business activities in reliance on our
                                        legitimate interests.
                                    </li>
                                    <li>
                                        To cooperate with public and government authorities, courts or regulators in
                                        accordance with our legal obligations under applicable laws to the extent this
                                        requires the processing or disclosure of Personal Information to protect our
                                        rights or is necessary for our legitimate interest in protecting against misuse
                                        or abuse of our Site and Apps, protecting personal property or safety, pursuing
                                        remedies available to us and limiting our damages, complying with judicial
                                        proceedings, court orders or legal processes, or responding to lawful requests.
                                    </li>
                                </ul>
                            </div>
                            <div className="line" id="third_parties">
                                <h2 className="pp_title">Information and third parties</h2>
                                <span>
                                    The Company uses internal means to process any personal information (including
                                    storage, procession, and evaluation), so it stays closed for third parties, except
                                    for additional serviceability (statistics and analytics).  No personal information
                                    shall be disclosed without the personal consent of the User. The Company may share
                                    non-Personal Data, such as depersonalized, aggregated user statistics with third
                                    parties, as e.g. Google Analytics, Facebook Analytics, Firebase.<br />
                                    We maintain presences on social media platforms, such as LinkedIn, Facebook,
                                    Twitter Any information you may submit to us via social media is done at your own
                                    risk without any expectation of privacy. We cannot control the actions of other
                                    users of these platforms or the actions of the platforms themselves. Your
                                    interactions with those features and platforms are governed by the privacy
                                    policies of the companies that provide them.<br />
                                    The legal issue of disclosure is considered as a reasonable reaction on a legally
                                    supported request. These may include legally authorized requests from jurisdictions
                                    outside the country of the Company registration and when that jurisdiction affects
                                    the User. The Company may disclose data to another company, organization,
                                    individual, or other entity to prevent fraud, technical or security issues,
                                    protection against harm to the rights, property or security of the Company, other
                                    Users or the public as required or permitted by law.
                                </span>
                            </div>
                            <div className="line" id="company_ensures">
                                <h2 className="pp_title ">
                                    How the Company ensures the security of the data
                                </h2>
                                <span>
                                    The Company makes every effort to use commercially reasonable means of the guard
                                    to protect and secure the User’s personal information. This includes data security
                                    and prevention of unauthorized access, alteration, disclosure, or destruction of
                                    such personal information.<br />
                                    The measures we take to ensure security include:
                                </span>
                                <ul>
                                    <li>
                                        The databases are kept separate to prevent corruption and overlap.
                                    </li>
                                    <li>
                                        The data is mirrored and regularly backed up.
                                    </li>
                                    <li>
                                        Our offices are protected with personal card access.
                                    </li>
                                    <li>
                                        Our office network is heavily segmented and centrally monitored.
                                    </li>
                                    <li>
                                        We continuously train employees on best security practices, including how to
                                        identify phishing scams and hackers.
                                    </li>
                                    <li>
                                        All employees sign a Non-disclosure agreement outlining their responsibility
                                        in protecting any data that should stay private.
                                    </li>
                                    <li>
                                        The data transferred from the Apps to the server and vice versa is encrypted.
                                    </li>
                                </ul>
                                <span>
                                    However, it is impossible to guarantee the entire safety of personal information
                                    during the processes of data transmission through the Internet. Namely, if any
                                    issue occurs which was not caused directly by the Company, no person or the User
                                    should claim for a refund for material or non-material damage.
                                </span>
                            </div>
                            <div className="line" id="protection_rights">
                                <h2 className="pp_title">
                                    Your data protection rights
                                </h2>
                                <span>
                                    You are  the sole owner of your private data, so you can manage it according to
                                    data  protection rights: to access, correct, update, port, delete, restrict, object
                                    to our processing your personal information.<br />
                                    The User is responsible for the accuracy of the information they submit to the
                                    Company. In case if the User thinks that the personal information processed by the
                                    Company is incomplete or inaccurate, they have the right to request clarification,
                                    rectification, completion, or erasure of such personal information. You may submit
                                    your requests to satisfy your data protection rights to
                                    <a href="mailto:help@delibroom.com">help@delibroom.com</a>. Such a request is to
                                    proceed in 28 days after receipt of such mail.
                                </span>
                                <span>
                                    You have the right to complain to the data protection authority in your respective
                                    country about the violation of Personal Information rights. Contact details for
                                    data protection authorities in the EEA and
                                    <a href="https://ico.org.uk/make-a-complaint/" target="_blank">UK</a> are available
                                    <a href="https://edps.europa.eu/data-protection/our-role-supervisor/complaints_en"
                                       target="_blank">here</a>.
                                </span>
                                <span>
                                    In case the information from you is taken on the basis of consent, you can withdraw
                                    this consent anytime. Such withdrawal will not affect the information processed
                                    prior to your consent, and can not affect the information collected on the lawful
                                    processing grounds other than consent.
                                </span>
                            </div>
                            <div className="line" id="legal_basis">
                                <h2 className="pp_title">
                                    Legal basis for processing of Personal Information
                                </h2>
                                <span>
                                    This section is for EEA and UK visitors. The legal basis for personal information
                                    collection depends on the context we collect it. Most of the other information is
                                    collected on the basis of legitimate interests and the purposes for such collection
                                    are described in the section For what purpose the company collects the information.
                                    They typically include improving, maintaining, providing and enhancing the
                                    technology applied in the site and applications and ensuring its security. We may
                                    need the personal information to perform a contract with you in case you start payed
                                    subscription, though it includes the third party - distribution store. In some
                                    limited cases, we may also have a legal obligation to collect personal information
                                    from you. If we ask you to provide personal information to comply with a legal
                                    requirement or to perform a contract with you, we will make this clear at the
                                    relevant time and advise you whether the provision of your personal information is
                                    mandatory or not, as well as of the possible consequences if you do not provide your
                                    personal information. Where required by law, we will collect your personal
                                    information (e.g. to install cookies to your device) ony after your consent.
                                </span>
                            </div>
                            <div className="line" id="opt-out">
                                <h2 className="pp_title">
                                    Your ability to opt-out
                                </h2>
                                <span>
                                    We do not provide any subscription to marketing materials, the only letters you may
                                    receive are transactional emails and support center requests satisfaction.
                                </span>
                            </div>
                            <div className="line">
                                <h2 className="pp_title">
                                    International transfers
                                </h2>
                                <span>
                                    We have offices located in Kropyvnytskyi and Lviv, Ukraine, so the staff managing
                                    your data is located in Ukraine. The servers are located in Germany. The servers of
                                    our third-party contractors are located in the US (Google, Firebase). They have
                                    their own Privacy Policies describing the specific points of the processing the
                                    information they receive and measures they take to ensure its security.
                                </span>
                            </div>
                            <div className="line">
                                <h2 className="pp_title">
                                    Retention of data
                                </h2>
                                <span>
                                    We retain personal information only when we have an ongoing legitimate or contract
                                    business or legal responsibilities to do so. Our retention periods depend on the
                                    data involved but mostly they base on the following:
                                </span>
                                <ul>
                                    <li>
                                        Whether we have legal or contractual obligations with our customers or partners.
                                    </li>
                                    <li>
                                        Whether our clients would reasonably expect we retain the data until the
                                        sufficient time passed from the moment of our previous contact.
                                    </li>
                                </ul>
                                <span>
                                    In case the data was deleted by you, though it was already stored in the backup
                                    server, the deletion of information from such server will take place in the next
                                    possible session. Until then, it will be securely stored and protected from any
                                    processing.
                                </span>
                            </div>
                            <div className="line" id="personal_information">
                                <h2 className="pp_title">
                                    How the personal information can be updated or deleted
                                </h2>
                                <span>
                                    The User is responsible for accuracy of the information they submit to the Company.
                                    In case if the User thinks that the personal information processed by the Company
                                    incompletely or inaccurately, they have the right to request clarification,
                                    rectification, completion or erasure of such personal information at
                                    <a href="mailto:info@delibroom.com">info@delibroom.com</a>. Such request is to be
                                    proceeded in 7 days after receipt of such mail. The personal information of the User
                                    can be deleted from any storage means on the demand of such User or after 1 year
                                    from the date of termination of the Application subscription.
                                </span>
                                <span>
                                    If you have any <b>additional questions</b> about the collection or usage of data
                                    not mentioned in the document, please contact the Company at
                                    <a href="mailto:help@delibroom.com">help@delibroom.com</a>. As far as the collection
                                    of information happens rarely and the company has less than 250 people, we have no
                                    appointed DPO for citizens of EEA and UK. Though we respect your privacy and are
                                    ready to assist you in any questions regarding privacy.
                                </span>
                            </div>
                            <div className="line" id="cookie">
                                <h2 className="pp_title">Cookie policy</h2>
                                <span>
                                    The Company uses cookies or similar technologies on the Site. Cookie - a special
                                    small file that collects information about the usage of the Site. It is needed to
                                    improve the operational experience, analytical or marketing activities.
                                </span>
                                <span>
                                    The cookies that are set by the website that offers the services are called
                                    &ldquo;first-party&rdquo; cookies. Cookies set by parties other than the website
                                    owner are called &ldquo;third-party cookies&rdquo;. Such types of cookies can
                                    transfer the info to these parties to enable their functionality. The third-party
                                    cookies can recognize you both on the website you visit and the services the cookie
                                    belongs to.
                                </span>
                                <span>
                                    Here is the complementary table listing the types of cookies, periods of their
                                    storage and purposes of installation.
                                </span>
                                <table className="cookiesTable">
                                    <tr>
                                        <th>Name</th>
                                        <th>Provider</th>
                                        <th>Type</th>
                                        <th>Expiry</th>
                                        <th>Purpose</th>
                                    </tr>
                                    <tr>
                                        <td>1P_JAR</td>
                                        <td>Google</td>
                                        <td>Statistics</td>
                                        <td>1 month</td>
                                        <td>To calculate visitor, session and campaign data for our analytics reports</td>
                                    </tr>
                                    <tr>
                                        <td>_gat_gtag</td>
                                        <td>Google</td>
                                        <td>Statistics</td>
                                        <td>1 minute</td>
                                        <td>To store a unique user ID</td>
                                    </tr>
                                    <tr>
                                        <td>_gid</td>
                                        <td>Google</td>
                                        <td>Statistics</td>
                                        <td>1 day</td>
                                        <td>To count and track pageviews</td>
                                    </tr>
                                    <tr>
                                        <td>_ga</td>
                                        <td>Google</td>
                                        <td>Statistics</td>
                                        <td>1 year</td>
                                        <td>To count and track pageviews</td>
                                    </tr>
                                    <tr>
                                        <td>wd</td>
                                        <td>Facebook</td>
                                        <td>Functional</td>
                                        <td>1 week</td>
                                        <td>To read screen resolution</td>
                                    </tr>
                                    <tr>
                                        <td>fr</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>3 month</td>
                                        <td>To provide ad delivery or retargeting</td>
                                    </tr>
                                    <tr>
                                        <td>presence</td>
                                        <td>Facebook</td>
                                        <td>Functional</td>
                                        <td>Session</td>
                                        <td>To support use of Messenger chat windows</td>
                                    </tr>
                                    <tr>
                                        <td>spin</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>1 day</td>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>x-referer</td>
                                        <td>Facebook</td>
                                        <td>Functional</td>
                                        <td>Session</td>
                                        <td>Shows the source where user came from to visit the site</td>
                                    </tr>
                                    <tr>
                                        <td>m_pixel_ratio</td>
                                        <td>Facebook</td>
                                        <td>Functional</td>
                                        <td>Session</td>
                                        <td>To improve the experience offered to users based on their device settings</td>
                                    </tr>
                                    <tr>
                                        <td>c_user</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>30 days</td>
                                        <td>To store a unique user ID</td>
                                    </tr>
                                    <tr>
                                        <td>sb</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>2 years</td>
                                        <td>To store browser details</td>
                                    </tr>
                                    <tr>
                                        <td>_fbp</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>3 month</td>
                                        <td>To store and track visits across websites</td>
                                    </tr>
                                    <tr>
                                        <td>dpr</td>
                                        <td>Facebook</td>
                                        <td>Functional</td>
                                        <td>7 days</td>
                                        <td>To improve the experience offered to users based on their device settings</td>
                                    </tr>
                                    <tr>
                                        <td>_fbp</td>
                                        <td>Delibroom</td>
                                        <td>Marketing</td>
                                        <td>3 month</td>
                                        <td>To store and track visits across websites</td>
                                    </tr>
                                    <tr>
                                        <td>xs</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>3 month</td>
                                        <td>To store a unique session ID</td>
                                    </tr>
                                    <tr>
                                        <td>delibroom-cookie-accepted</td>
                                        <td>Delibroom</td>
                                        <td>Essential</td>
                                        <td>3 month</td>
                                        <td>To store cookie preferences</td>
                                    </tr>
                                    <tr>
                                        <td>datr</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>2 years</td>
                                        <td>To provide fraud prevention</td>
                                    </tr>
                                    <tr>
                                        <td>usida</td>
                                        <td>Facebook</td>
                                        <td>Marketing</td>
                                        <td>Session</td>
                                        <td>Collects a combination of the user’s browser and unique identifier, used
                                            to tailor advertising to users</td>
                                    </tr>
                                </table>
                                <span>
                                    This allows the browser to collect additional information about the User’s device
                                    that intended to be used for improvement of the Site. This special files are stored
                                    in browser/device from a website, application or advertising. They may collect
                                    information including the name of Internet service provider, the IP address of the
                                    device, the date and time, what type of device or browser is used, or preferred
                                    functions when using the Site. The User can set browser to refuse all or some
                                    browser cookies, or to alert when websites set or access cookies. Please note,
                                    that if to disable or refuse cookies (especially essential cookies), some parts of
                                    the Site may become inaccessible or not function properly.
                                </span>
                                <span>
                                    The purposes of cookies collection can be segregated into the following categories:
                                </span>
                                <ul>
                                    <li>
                                        Functional necessity to enable operation of strictly necessary website services.
                                        Features include access to secure areas. You can not refuse them without
                                        impacting the serviceability of our websites. You can anytime block or delete
                                        them by changing your browser settings as described below in section How I
                                        control cookies.
                                    </li>
                                    <li>
                                        Personalization on different devices. By using cookie files we can discover what
                                        kind of device is used and improve the effectiveness of the Site for this
                                        particular device. Any bugs appearing will be easier to eliminate if to know
                                        clearly the device type that is used.
                                    </li>
                                    <li>
                                        Analytics to distinguish different users that have browser sessions, but can not
                                        identify the same users from various devices. These cookies collect information
                                        that helps us to understand how our websites are being used or how effective our
                                        marketing campaigns are. These cookies may be set by third-party providers whose
                                        services are added to our pages.
                                    </li>
                                    <li>
                                        Integration and sharing capabilities with social media networking and making
                                        advertising messages more relevant to users interests.
                                    </li>
                                    <li>
                                        Essential cookies to store the preferences of visitors on cookies use and other
                                        core activities that are in the primary functions.
                                    </li>
                                </ul>
                                <div className="line">
                                    <h2 className="pp_title">
                                        Other tracking technologies
                                    </h2>
                                    <span>
                                        We and our third-party partners may use other, similar technologies from time to
                                        time, such as pixels and web beacons. Pixel - a small fragment of code that
                                        collects information about performed actions when using the Site. It is needed
                                        to discover engagement in particular content of the Site. That helps to improve
                                        the quality of the services provided. Web beacons allow tracking of the opened
                                        emails and analyze the behavior of the subscribers. That also helps to
                                        understand whether a user came from ads displayed on a third-party website, to
                                        improve website performance.
                                    </span>
                                </div>
                                <div className="line">
                                    <h2 className="pp_title">
                                        Targeted online advertising
                                    </h2>
                                    <span>
                                        We have engaged one third-party service provider to track and analyze the
                                        behaviour and usage of the website. This third party (Facebook) uses cookies,
                                        web beacons, pixel tags and similar tracking technologies to infer your
                                        interests and deliver the ads based on your preferences and browser activities.
                                        They track the users who have landed on certain pages for the purpose of target
                                        remarketing in the Facebook network. The information such as telephone number,
                                        email address, Facebook ID may be shared with Facebook in protected form. You
                                        can modify your Facebook ad settings to restrict those advertising, or do not
                                        agree with the marketing section of cookies selection. Learn more about the
                                        different types of Facebook cookies
                                        <a href="https://www.facebook.com/policies/cookies/" target="_blank">here</a>.
                                    </span>
                                </div>
                                <div className="line">
                                    <h2 className="pp_title">
                                        How can I control cookies?
                                    </h2>
                                    <span>
                                        You can control which cookies are installed on your device and by default you
                                        receive the maximum level of privacy. If any excessive cookies already are on
                                        your device, use the guidelines from the next section to delete them.
                                    </span>
                                </div>
                                <div className="line">
                                    <h2 className="pp_title">
                                        Browser settings
                                    </h2>
                                    <span>
                                        You can set a web browser to refuse all or some browser cookies or to alert
                                        when websites set or access cookies.<br />
                                        To remove cookies from your device use these guidelines:
                                        <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank">Internet Explorer</a>,
                                        <a href="https://help.opera.com/en/how-to-clear-browser-cache-history-cookies" target="_blank">Opera</a>,
                                        <a href="https://support.apple.com/en-ph/guide/safari/sfri11471/mac" target="_blank">Safari</a>,
                                        <a href="https://support.google.com/accounts/answer/61416" target="_blank">Chrome</a>,
                                        <a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences" target="_blank">Firefox</a>.
                                    </span>
                                    <span>
                                        Please note, that if to disable or refuse cookies (especially essential
                                        cookies), some parts of the Site may become inaccessible or not function
                                        properly. Additionally, the User can refuse the provision of information to
                                        Google Analytics by using the corresponding opt-out in the web browser
                                        <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">here</a>.
                                    </span>
                                </div>
                            </div>
                            <div className="line">
                                <h2 className="pp_title">
                                    Changes of the document
                                </h2>
                                <span>
                                    The Company reserves the right to change the Policy at any time. In case if the
                                    Privacy and Cookie Policy is changed, the special entry is made below for everyone
                                    to track such alterations.{" "}
                                </span>
                            </div>
                            <div className="line">
                                <h2 className="pp_title">Latest changes:</h2>
                                <span>
                                    January 21, 2022 - v.1.1 is published<br />
                                    May 31, 2019 - Initial draft published
                                </span>
                            </div>
                        </div>

                        <AnyQuestions openModal={this.props.openModal} />
                    </div>
                </section>
            </>
        );
    }
}

export default PrivacyPolice;
