import React, { Component } from "react";
import Slider from "react-slick";
import slide1 from "../../media/img/col_phone1.png";
import slide2 from "../../media/img/col_phone4.png";
import slide3 from "../../media/img/col_phone3.png";
import slide5 from "../../media/img/col_phone5.png";
import slide6 from "../../media/img/col_phone6.png";
import InstructionArr from "../icons/howItWork/InstructionArrow";

const settings = {
  className: "how_it_work_slider",
  dots: false,
  arrow: true,
  infinite: true,
  autoplaySpeed: 0,
  speed: 600,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 3,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

class SlideInstructions extends Component {
  render() {
    const prev = () => {
      document
        .querySelector(".wrap_instruction .slick-arrow.slick-prev")
        .click();
    };
    const next = () => {
      document
        .querySelector(".wrap_instruction .slick-arrow.slick-next")
        .click();
    };
    return (
      <div className="wrap_instruction">
        <div className="arrow">
          <div onClick={prev}>
            <InstructionArr way="left" />
            <span>
              Search by the technical means to find the perfect option
            </span>
          </div>
        </div>
        <Slider {...settings}>
          <div className="slide">
            <h3>Start a meeting right now by using “Quick reservation”</h3>
            <img src={slide1} alt="" />
          </div>

          <div className="slide">
            <h3>Set notifications and always be on time for the meetings</h3>
            <img src={slide2} alt="" />
          </div>

          <div className="slide">
            <h3>
              Switch the workspaces to track the meetings schedule for each of
              them
            </h3>
            <img src={slide3} alt="" />
          </div>

          <div className="slide">
            <h3>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo,
              officia.
            </h3>
            <img src={slide5} alt="" />
          </div>

          <div className="slide">
            <h3>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo,
              officia.
            </h3>
            <img src={slide6} alt="" />
          </div>
        </Slider>
        <div className="arrow">
          <div onClick={next}>
            <InstructionArr way="right" />
            <span>Plan your meetings schedule on the go</span>
          </div>
        </div>
      </div>
    );
  }
}

export default SlideInstructions;
