import React from "react";

const Icon7 = props => (
  <svg x="0px" y="0px" viewBox="0 0 127 103" xmlSpace="preserve" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1={1584}
      y1={-6558.5151}
      x2={1706}
      y2={-6558.5151}
      gradientTransform="matrix(1 0 0 -1 -1581.5 -6506.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path
      d="M124.5 15.4v73.3c0 3.1-2.6 5.7-5.7 5.6H8.2c-3.1 0-5.7-2.5-5.7-5.6V15.4c0-3.1 2.6-5.7 5.7-5.6h110.6c3.1-.1 5.7 2.4 5.7 5.6z"
      fill="#6439ac"
      stroke="url(#a)"
      strokeWidth={5}
    />
    <path
      d="M63 31.5c9.7 0 17.5 7.8 17.5 17.5S72.7 66.5 63 66.5 45.5 58.7 45.5 49 53.3 31.5 63 31.5h0zm50.5-17h6v75h-6v-75zm-32 63h14v5h-14v-5zm-16 0h14v5h-14v-5zm-53-4h38v5h-38v-5zm0-54h5v5h-5v-5zm80 0h18v5h-18v-5zm-80 58h47v5h-47v-5zm84 0h14v5h-14v-5z"
      fill="none"
      stroke="#4bb4ff"
    />
    <linearGradient
      id="b"
      gradientUnits="userSpaceOnUse"
      x1={1612.4}
      y1={105}
      x2={1612.4}
      y2={104}
      gradientTransform="matrix(1 0 0 -1 -1581.5 -6506.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path d="M30.9 90.2v10.3" fill="#6439ac" stroke="url(#b)" strokeWidth={5} />
    <linearGradient
      id="c"
      gradientUnits="userSpaceOnUse"
      x1={1677.6}
      y1={105}
      x2={1677.6}
      y2={104}
      gradientTransform="matrix(1 0 0 -1 -1581.5 -6506.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path d="M96.1 90.2v10.3" fill="#6439ac" stroke="url(#c)" strokeWidth={5} />
    <linearGradient
      id="d"
      gradientUnits="userSpaceOnUse"
      x1={1648.16}
      y1={105}
      x2={1648.16}
      y2={104}
      gradientTransform="matrix(1 0 0 -1 -1581.5 -6506.5)"
    >
      <stop offset={0} stopColor="#e826a6" />
      <stop offset={1} stopColor="#53adfa" />
    </linearGradient>
    <path d="M66.7 2.5v10.3" fill="#6439ac" stroke="url(#d)" strokeWidth={5} />
  </svg>
);

export default Icon7;
