import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from "react-scroll";
import topPhone from "../../media/img/top_phone.png";
import bottomPhone from "../../media/img/bottom_phone.png";

import DownloadLinks from "../downloadLinks/downloadLinks";

import topTablet from "../../media/img/top_tablet.png";
import bottomTablet from "../../media/img/bottom_tablet.png";

import slidePhone from "../../media/img/mobile.png";
import slideTabletReserved from "../../media/img/tablet-reserved.png";
import slideTabletFree from "../../media/img/tablet-free.png";

import mobileDevices from "../../media/img/mobileDevices.png";
import Responsive from "react-responsive";

const Mobile = props => <Responsive {...props} maxWidth={1024} />;

const settings = {
  className: "slider",
  dots: true,
  arrow: true,
  infinite: false,
  autoplaySpeed: 0,
  speed: 600,
  autoplay: false,
  dotsClass: "main_slider_dots",
  slidesToScroll: 1,
  slidesToShow: 1,
  pauseOnHover: true,
  beforeChange(currentSlide, nextSlide) {
    let w = window.innerWidth;
    let start = 0;
    if (w >= 1024) {
      if (start === 0) {
        start = 1;
        let one = document.querySelector(".slide.one");
        document.querySelector(".slide.one").className = "";
        one.className += "slide one";
      }
    }
  },

  responsive: [
    {
      breakpoint: 1024,
      settings: "unslick"
    }
  ]
};

class MainSlider extends Component {
  constructor(props) {
    super(props);
    this.slide = this.slide.bind(this);
  }

  slide(y) {
    y > 0 ? this.slider.slickNext() : this.slider.slickPrev();
  }

  loadLastSlide() {
    if (window.innerWidth >= 1024) {
      let scrollPage = document.documentElement.scrollTop;

      let sliderHeight = document.querySelector(".slider").clientHeight;
      if (
        scrollPage >= sliderHeight ||
        document.body.scrollTop >= sliderHeight
      ) {
        document
          .querySelector(".main_slider_dots")
          .lastChild.querySelector("button")
          .click();
      }
    }
  }

  howToUse = e => {
    document.querySelector(".left_navigation a:nth-child(1)").click();
  };
  attrDevice = w => {
    if (document.querySelector(".groupe_device")) {
      let devices = document.querySelector(".groupe_device");
      if (w <= 1024) {
        devices.setAttribute("style", "");
      }
    }
  };

  linkAnimation = e => {
    let wrap = e.currentTarget;
    let w = window.innerWidth;
    wrap
      .closest(".download_links_wrap")
      .querySelectorAll("p")
      .forEach(el => {
        if (!el.classList.contains("done")) {
          el.classList.add("done");

          if (w >= 1024) {
            let arr = el.innerText.split("");

            let spans = arr.map((item, index) => {
              return `<span key=${index} style="transition-delay:${index /
                30}s;">${item}</span>`;
            });

            el.innerHTML = spans.join("");
          }
        }
      });
  };

  componentDidMount() {
    document.querySelector("header").classList.remove("other_page");

    let i = 0;
    let w = window.innerWidth;
    this.attrDevice(w);

    function paste(addClass, removeClass) {
      if (document.querySelector(".slide.one")) {
        document.querySelector(".slide.one").classList.add(addClass);
        document.querySelector(".slide.one").classList.remove(removeClass);
      }
    }

    function animRun() {
      var arr = [
        "qw",
        "first_animation",
        "second_animation",
        "third_animation",
        "end_animation"
      ];
      var timer;
      let w = window.innerWidth;
      let sliderHeight = document.querySelector(".slider").clientHeight / 3;

      timer = setInterval(() => {
        if (
          +localStorage.getItem("impulse") === 0 &&
          document.querySelector(".slick-slider.slider")
        ) {
          if (w >= 1024) {
            if (
              document.documentElement.scrollTop <= sliderHeight ||
              document.body.scrollTop <= sliderHeight
            ) {
              if (
                document
                  .querySelector('[data-index="0"]')
                  .classList.contains("slick-current")
              ) {
                i++;
                let nowClass, prevClass;
                if (i === arr.length) {
                  i = 0;
                }
                nowClass = arr[i];
                if (i === 0) {
                  prevClass = arr[arr.length - 1];
                } else {
                  prevClass = arr[i - 1];
                }
                paste(nowClass, prevClass);
              } else {
                clearInterval(timer);
              }
            } else {
              clearInterval(timer);
            }
          } else {
            clearInterval(timer);
          }
        } else {
          clearInterval(timer);
        }
      }, 2200);
      // setTimeout(() => {
      //     if (localStorage.getItem("impulse") === 0) {
      //         localStorage.setItem("impulse", 1);
      //     }
      // }, 2200 * 11);
    }

    this.loadLastSlide();

    function movePlanet(x, y) {
      let rosePlanet = document.querySelector(".rose_planet");

      rosePlanet.style.WebkitTransform =
        "translate(" + x + "px," + y + "px) rotate(30deg)";
      rosePlanet.style.msTransform =
        "translate(" + x + "px," + y + "px) rotate(30deg)";
      rosePlanet.style.transform =
        "translate(" + x + "px," + y + "px) rotate(30deg)";
    }

    function movePhone(x, y) {
      if (w >= 1024) {
        x = x / 2;
        y = y / 2;
        let topPhone = document.querySelector(".top_phone");
        let bottomPhone = document.querySelector(".bottom_phone");
        topPhone.style.WebkitTransform = `translate(${x}px, 0px)`;
        topPhone.style.msTransform = `translate(${x}px, 0px)`;
        topPhone.style.transform = `translate(${x}px, 0px)`;
        bottomPhone.style.WebkitTransform = `translate(${x}px, 0px)`;
        bottomPhone.style.msTransform = `translate(${x}px, 0px)`;
        bottomPhone.style.transform = `translate(${x}px, 0px)`;
      }
    }

    function moveTablet(x, y) {
      if (w >= 1024) {
        x = x / 2;
        y = y / 2;
        let topTablet = document.querySelector(".top_tablet");
        let bottomTablet = document.querySelector(".bottom_tablet");
        topTablet.style.WebkitTransform = `translate(${x}px, ${y}px)`;
        topTablet.style.msTransform = `translate(${x}px, ${y}px)`;
        topTablet.style.transform = `translate(${x}px, ${y}px)`;
        bottomTablet.style.WebkitTransform = `translate(${x}px, ${y}px)`;
        bottomTablet.style.msTransform = `translate(${x}px, ${y}px)`;
        bottomTablet.style.transform = `translate(${x}px, ${y}px)`;
      }
    }

    if (document.querySelector(".main_content.first")) {
      if (document.querySelector(".slider")) {
        document.querySelectorAll(".slider .slide").forEach(function(e) {
          e.addEventListener("mousemove", function(el) {
            if (w >= 1025) {
              let x = (el.pageX - this.offsetLeft) / 20;
              let y = (el.screenY - this.offsetTop) / 20;
              if (el.currentTarget.classList.contains("one")) {
                movePlanet(x, y);
              } else if (el.currentTarget.classList.contains("two")) {
                movePhone(x, y);
              } else if (el.currentTarget.classList.contains("three")) {
                moveTablet(x, y);
              }
            }
            // if (localStorage.getItem("impulse") !== 0) {
            //     localStorage.setItem("impulse", 0);
            //     animRun();
            // }
          });
        });
      }
    }
    if (w >= 1025) {
      if (document.querySelector('.slider [data-index="0"]')) {
        setTimeout(() => {
          if (
            document
              .querySelector('.slider [data-index="0"]')
              .classList.contains("slick-current")
          ) {
            animRun();
          }
        }, 2500);
      }
    }

    let jumpToNextSlide = () => {
      var intervalId;
      var targetElement = document.getElementById("begin_main_content");
      if (!document.body.classList.contains("safari")) {
        intervalId = setInterval(function() {
          if (targetElement) {
            var offset = targetElement.offsetTop;
            var documentTop = document.documentElement.scrollTop;

            if (offset - documentTop > 0) {
              document.documentElement.scrollTop += 10;
            } else {
              clearInterval(intervalId);
            }
          }
        }, 10);
      } else {
        intervalId = setInterval(function() {
          if (targetElement) {
            var offset = targetElement.offsetTop;
            var documentTop = document.body.scrollTop;

            if (offset - documentTop > 0) {
              document.body.scrollTop += 10;
            } else {
              clearInterval(intervalId);
            }
          }
        }, 10);
      }
    };

    let slickListDiv = document.querySelector(".slider .slick-list");
    var last_time = new Date();
    if (window.innerWidth >= 1025) {
      slickListDiv.addEventListener("wheel", event => {
        event.preventDefault();
        var now = new Date();
        if (window.innerWidth >= 992) {
          if (now - last_time >= 1100) {
            if (event.deltaY >= 0) {
              this.slider.slickNext();
              if (
                document
                  .querySelector(".slider .slick-next")
                  .classList.contains("slick-disabled")
              ) {
                setTimeout(() => {
                  jumpToNextSlide();
                }, 100);
              }
            } else {
              this.slider.slickPrev();
            }

            last_time = new Date();
          }
        } else {
          if (event.deltaY >= 0) {
            this.slider.slickNext();
          } else {
            this.slider.slickPrev();
          }
        }
      });
    }
  }

  render() {
    return (
      <>
        <div id="start_app" />
        <Slider {...settings} ref={slider => (this.slider = slider)}>
          <div className="slide one">
            <div className="container">
              <div className="present">
                <h1 className="present_title one  textAnimation">
                  <span>Meeting room</span>
                </h1>
                <h2 className="present_title two  textAnimation">
                  scheduling system
                </h2>
                <div className="present_line">
                  <div className="present_info ">
                    scroll
                    <br /> to navigate
                  </div>
                  <div className="description">
                    <p className="description_text">
                      Delibroom is the mobile app for mobile phones and tablets
                      that aims to solve problems with organizing meetings as
                      well as providing a convenient tool for monitoring planned
                      meetings.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="groupe_device">
              <img src={mobileDevices} alt="" className="mobileDevices" />
              <div className="pulse_to_second_tablet">
                <div className="end" />
              </div>

              <div className="pulse_to_third_tablet">
                <div className="end" />
                <div className="top" />
              </div>

              <div className="pulse_to_first_tablet">
                <div className="end" />
                <div className="bottom" />
              </div>

              <div className="name_room room1">
                <span>#</span>
                <h3 className="number">1</h3>
              </div>
              <div className="name_room room2">
                <span>#</span>
                <h3 className="number">2</h3>
              </div>
              <div className="name_room room3">
                <span>#</span>
                <h3 className="number">3</h3>
              </div>
              <div className="slidePhone">
                <img src={slidePhone} alt="" />
              </div>
              <div className="slideTabletReserved">
                <img src={slideTabletFree} alt="" />
                <img src={slideTabletReserved} alt="" />
              </div>
              <div className="slideTabletFree">
                <img src={slideTabletFree} alt="" />
                <img src={slideTabletReserved} alt="" />
              </div>
              <div className="slideTabletFreeBottom">
                <img src={slideTabletFree} alt="" />
                <img src={slideTabletReserved} alt="" />
              </div>
              <svg
                className="line1"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 397.6 705.2"
                style={{
                  enableBackground: "new 0 0 397.6 705.2"
                }}
                xmlSpace="preserve"
              >
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1783.5017"
                  y1={-575}
                  x2="1783.5017"
                  y2={-230}
                  gradientTransform="matrix(1 0 0 -1 -1585.5 129.719)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  className="st0"
                  d="M0.5,437.7l171-78l224,268l-172,77L0.5,437.7z"
                />
                <linearGradient
                  id="Shape_659_copy_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1818.3024"
                  y1={-293}
                  x2="1818.3024"
                  y2={128}
                  gradientTransform="matrix(1 0 0 -1 -1585.5 129.719)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  id="Shape_659_copy"
                  className="st1"
                  d="M222.5,422.7l173-77l-288-344l-38,17"
                />
              </svg>
              <svg
                className="line2"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 588.6 751.7"
                style={{
                  enableBackground: "new 0 0 588.6 751.7"
                }}
                xmlSpace="preserve"
              >
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1783.5017"
                  y1={-529}
                  x2="1783.5017"
                  y2={-184}
                  gradientTransform="matrix(1 0 0 -1 -1585.5 222.156)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  className="st0"
                  d="M0.5,484.2l171-78l224,268l-172,77L0.5,484.2z"
                />
                <linearGradient
                  id="Shape_659_copy_2_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1990.3058"
                  y1="-247.4568"
                  x2="1990.3058"
                  y2="220.5432"
                  gradientTransform="matrix(1 0 0 -1 -1585.5 222.156)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  id="Shape_659_copy_2"
                  className="st1"
                  d="M222.5,469.2l173-77L268.4,240.3L586.5,98.2l-83-97"
                />
              </svg>
              <svg
                className="line3"
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 807.6 346.2"
                style={{
                  enableBackground: "new 0 0 807.6 346.2"
                }}
                xmlSpace="preserve"
              >
                <linearGradient
                  id="Shape_659_copy_4_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="2100.6162"
                  y1="-817.8869"
                  x2="2100.6162"
                  y2="-611.1059"
                  gradientTransform="matrix(1 0 0 -1 -1585.5 -589.2739)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  id="Shape_659_copy_4"
                  className="st0"
                  d="M222.8,63.6l92.9-41.8l174.1,206.8l317-142l-26-29"
                />
                <linearGradient
                  id="SVGID_1_"
                  gradientUnits="userSpaceOnUse"
                  x1="1783.8058"
                  y1="-934.8869"
                  x2="1783.8058"
                  y2="-589.8869"
                  gradientTransform="matrix(1 0 0 -1 -1585.5 -589.2739)"
                >
                  <stop offset={0} style={{ stopColor: "#CC1DFF" }} />
                  <stop offset={1} style={{ stopColor: "#4BB4FF" }} />
                </linearGradient>
                <path
                  className="st1"
                  d="M0.8,78.6l171-78l224,268l-172,77L0.8,78.6z"
                />
              </svg>

              <svg
                className="general_communication"
                version="1.1"
                id="Layer_1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 808 782"
                style={{ enableBackground: "new 0 0 808 782" }}
                xmlSpace="preserve"
              >
                <path
                  className="st0"
                  d="M0 515l171-78 224 268-172 77L0 515zm224-15l171-77L107 79 69 96m247 363l173 205 318-141-26-30M268 271l317-142L478 1"
                >
                  <animate
                    id="Layer_1"
                    attributeName="stroke-dashoffset"
                    begin="Layer_1.onClick"
                    values="2921; 0"
                    dur="10s"
                    repeatCount={1}
                    fill="freeze"
                    calcMode="linear"
                  />
                </path>
              </svg>
            </div>
            <div className="rose_planet">
              <div className="planet" />
            </div>
          </div>

          <div className="slide two">
            <div className="wrapDevices">
              <img
                src={topPhone}
                alt=""
                className="animate_element top_phone"
              />
              <img
                src={bottomPhone}
                alt=""
                className="animate_element bottom_phone"
              />
            </div>
            <div className="container">
              <div className="present">
                <h2 className="present_title one textAnimation">
                  Book a meeting
                </h2>
                <h2 className="present_title two textAnimation">
                  anywhere, anytime
                </h2>
                <div className="bottom_line">
                  <div>
                    <span className="bottom_line_subtitle ">
                      Free plan – 1 workspace/ 2 meeting rooms
                    </span>
                    <span className="bottom_line_title ">
                      Download on iOS and Android for free:
                    </span>
                  </div>

                  <DownloadLinks
                    openModal={this.props.openModal}
                    device="tel"
                  />
                </div>
              </div>
              <div className="description">
                <p className="description_text">
                  The mobile app is used for internal organisation of meetings
                  and management of users as well as searching the most suitable
                  meeting room and monitoring planned meetings for all company
                  staff.
                </p>
                <div className="how_to_use " onClick={this.howToUse}>
                  <span>How to use</span>
                  <div className="plus">+</div>
                </div>
              </div>
            </div>
          </div>

          <div className="slide three">
            <div className="wrapDevices">
              <img
                src={topTablet}
                alt=""
                className="animate_element top_tablet"
              />
              <img
                src={bottomTablet}
                alt=""
                className="animate_element bottom_tablet"
              />
            </div>
            <div className="container">
              <div className="present">
                <h2 className="present_title one textAnimation">
                  Easy booking
                </h2>
                <h2 className="present_title two textAnimation">
                  at your fingertips
                </h2>
                <div className="bottom_line">
                  <div>
                    <span className="bottom_line_subtitle ">
                      Free plan – 1 workspace/ 2 meeting rooms
                    </span>
                    <span className="bottom_line_title ">
                      Download on iOS and Android for free:
                    </span>
                  </div>
                  <DownloadLinks
                    openModal={this.props.openModal}
                    device="tablet"
                  />
                </div>
              </div>
              <div className="description">
                <p className="description_text">
                  The tablet application can be used to monitor the status of
                  the conference room, book a room on the go, view the schedule
                  of this room.
                </p>
                <Link
                  activeClass="active startScroll"
                  spy={true}
                  delay={0}
                  smooth={true}
                  offset={0}
                  isDynamic={true}
                  duration={500}
                  to="how-to-use"
                  id="howToUse"
                  ignoreCancelEvents={false}
                  className="how_to_use next"
                >
                  <span>How to use</span>
                  <div className="plus">+</div>
                </Link>
              </div>
            </div>
          </div>
        </Slider>

        <Mobile>
          <div className="slider active mobileVersion">
            <div className="slick-active">
              <div className="slide one">
                <div className="container">
                  <div className="present">
                    <h1 className="present_title one textAnimation ">
                      <span> Meeting room</span>
                    </h1>
                    <h2 className="present_title two textAnimation ">
                      <span>scheduling system</span>
                    </h2>
                    <div className="present_line">
                      <div className="present_info ">
                        scroll
                        <br /> to navigate
                      </div>
                      <div className="description">
                        <p className="description_text">
                          Delibroom is the mobile app for mobile phones and
                          tablets that aims to solve problems with organizing
                          meetings as well as providing a convenient tool for
                          monitoring planned meetings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="groupe_device">
                  <img src={mobileDevices} alt="" className="mobileDevices" />
                </div>
                <div className="rose_planet">
                  <div className="planet" />
                </div>
              </div>

              <div className="slide two">
                <div className="wrapDevices">
                  <img
                    src={topPhone}
                    alt=""
                    className="animate_element top_phone"
                  />
                  <img
                    src={bottomPhone}
                    alt=""
                    className="animate_element bottom_phone"
                  />
                </div>
                <div className="container">
                  <div className="present">
                    <h2 className="present_title one textAnimation">
                      Book a meeting
                    </h2>
                    <h2 className="present_title two textAnimation">
                      anywhere, anytime
                    </h2>
                    <div className="bottom_line">
                      <div>
                        <span className="bottom_line_subtitle ">
                          Free plan – 1 workspace/ 2 meeting rooms
                        </span>
                        <span className="bottom_line_title ">
                          Download on iOS and Android for free:
                        </span>
                      </div>
                      <DownloadLinks
                        device="tel"
                        openModal={this.props.openModal}
                      />
                    </div>
                  </div>
                  <div className="description">
                    <p className="description_text">
                      The mobile app is used for internal organisation of
                      meetings and management of users as well as searching the
                      most suitable meeting room and monitoring planned meetings
                      for all company staff.
                    </p>
                    <div className="how_to_use " onClick={this.howToUse}>
                      <span>How to use</span>
                      <div className="plus">+</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="slide three">
                <div className="wrapDevices">
                  <img
                    src={topTablet}
                    alt=""
                    className="animate_element top_tablet"
                  />
                  <img
                    src={bottomTablet}
                    alt=""
                    className="animate_element bottom_tablet"
                  />
                </div>
                <div className="container">
                  <div className="present">
                    <h2 className="present_title one textAnimation">
                      Easy booking
                    </h2>
                    <h2 className="present_title two textAnimation">
                      at your fingertips
                    </h2>
                    <div className="bottom_line">
                      <div>
                        <span className="bottom_line_subtitle ">
                          Free plan – 1 workspace/ 2 meeting rooms
                        </span>
                        <span className="bottom_line_title ">
                          Download on iOS and Android for free:
                        </span>
                      </div>
                      <DownloadLinks
                        device="tablet"
                        openModal={this.props.openModal}
                      />
                    </div>
                  </div>
                  <div className="description">
                    <p className="description_text">
                      The tablet application can be used to monitor the status
                      of the conference room, book a room on the go, view the
                      schedule of this room.
                    </p>
                    <Link
                      activeClass="active startScroll"
                      spy={true}
                      delay={0}
                      smooth={true}
                      offset={0}
                      isDynamic={true}
                      duration={500}
                      to="how-to-use"
                      id="howToUse"
                      ignoreCancelEvents={false}
                      className="how_to_use next"
                    >
                      <span>How to use</span>
                      <div className="plus">+</div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Mobile>
      </>
    );
  }
}

export default MainSlider;
