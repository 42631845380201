import React, { Component } from "react";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";

class Horizontal extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            horizontal: 1,
        };
    }


    handleChangeHorizontal = value => {
        this.setState({
            horizontal: value
        });
    };


  

   
    render() {
        const { horizontal } = this.state;
        const horizontalLabels = {
            0: "0",
            5: "5",
            10: "10",
            15: "15",
            20: "20",
            25: "25",
            30: "30"
        };
        const formatPc = p => p;
        return (
            <>
               
                <div className="rangeSlider">
                    <p className="rangeSlider_title">
                        Slide to see pricing for different number of rooms
                    </p>
                    <Slider
                        min={0}
                        max={30}
                        value={horizontal}
                        labels={horizontalLabels}
                        format={formatPc}
                        tooltip={true}
                        step={1}
                        onChange={this.handleChangeHorizontal}
                    />
                </div>

                <div className="pricing_tariff_plans">
                    <div className="tariff_plans_col">
                        <div className="col">
                            <h3 className="title">Standard</h3>
                            <div className="price additional_info">
                                <span className="price_currency">usd</span>
                                <span className="price_number paid" >
                                    {formatPc(horizontal) * this.props.numerRate}
                                </span>
                                <span className="price_length">
                                    <span className="price_length_title">
                                    month
                                    </span>
                                    <span className="price_length_info">
                                        paid {this.props.titlePosition}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col">
                            <div className="available_rooms">
                                <span> {formatPc(horizontal) } room{formatPc(horizontal <= 1 ? "" : "s" ) }</span>
                                <span> unlimited workspaces</span>
                            </div>
                            <button
                                className="btn bright_blue present"
                                onClick={this.props.openModal}
                            >
                               try 14 days free
                            </button>
                        </div>
                        <div className="short_descript">
                            <span>
                            After 14 days free trial,  the fee will be charged automatically. Cancel anytime to stop future charges.
                            </span>
                        </div>
                    </div>
                    <div className="tariff_plans_col">
                        <div className="col">
                            <h3 className="title">Free</h3>
                            <div className="price">
                                <span className="price_currency">usd</span>
                                <span className="price_number">0</span>
                                <span className="price_length">
                                    <span className="price_length_title">
                                    month
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div className="col">
                            <div className="available_rooms">
                                <span> 2 rooms</span>
                                <span> 1 workspace</span>
                            </div>
                            <button
                                className="btn bright_blue  present"
                                onClick={this.props.openModal}
                            >
                                try it free
                            </button>
                        </div>
                        <div className="short_descript">
                            <span></span>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Horizontal;
