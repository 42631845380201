import React from "react";

const Icon1 = props => (
  <svg x="0px" y="0px" viewBox="0 0 104.2 122.6" xmlSpace="preserve" {...props}>
    <linearGradient
      id="a"
      gradientUnits="userSpaceOnUse"
      x1={2997.9993}
      y1={-6574.4912}
      x2={3102.001}
      y2={-6574.4912}
      gradientTransform="matrix(1 0 0 -1 -2998 -6531.97)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      d="M81.1 47c0-1.2-.6-2.4-1.5-3.2C64 29.5 40 29.5 24.4 43.8c-.9.8-1.4 2-1.5 3.2 0 1.2.4 2.4 1.3 3.3l8.4 8.5c1.6 1.7 4.2 1.8 5.9.3 7.8-6.7 19.3-6.7 27.1 0 1.7 1.5 4.3 1.4 5.9-.3l8.4-8.5c.8-.9 1.2-2.1 1.2-3.3zm-12.7 8.7c-9.4-8.1-23.3-8.1-32.7 0l-8.4-8.5c14-12.8 35.4-12.8 49.4 0l-8.3 8.5zm-9.9 13.2c-3.5-3.6-9.3-3.7-12.9-.2l-.2.2c-3.6 3.7-3.6 9.6 0 13.4 3.5 3.6 9.3 3.7 12.9.2l.2-.2c3.7-3.8 3.7-9.7 0-13.4zm-3.1 10.2c-1.8 1.9-4.9 1.9-6.8.1l-.1-.1c-1.9-2-1.9-5.1 0-7 1.8-1.9 4.9-1.9 6.8-.1l.1.1c1.9 1.9 1.9 5 0 7zm47.2-58.7c-28.2-27.2-73-27.2-101.2 0-1.8 1.8-1.9 4.6-.1 6.5l8.3 8.5c1.6 1.7 4.4 1.8 6.1.1C36 16.2 68 16.2 88.3 35.5c.8.8 1.9 1.2 3 1.2 1.2 0 2.3-.5 3.1-1.3l8.3-8.5c1.8-1.8 1.7-4.7-.1-6.5 0 .1 0 .1 0 0zM91.3 32.3c-22-21-56.6-21-78.6 0l-8.3-8.5C31-1.9 73-1.9 99.6 23.7l-8.3 8.6z"
      fill="url(#a)"
    />
    <path
      d="M32.1 122.6h1.5l2.3-7.9 2.3 7.9h1.5l3.1-10.5h-1.6l-2.3 8-2.3-8h-1.2l-2.3 8-2.3-8h-1.6l2.9 10.5zm16.2 0h1.5v-10.5h-1.5v10.5zm7.4 0h1.5V118h4v-1.2h-4v-3.3h5.4v-1.3h-6.9v10.4zm12.2 0h1.5v-10.5h-1.5v10.5z"
      fill="#4bb4ff"
    />
    <path
      d="M85.2 95V62h0L74 74.4M91.8 60h0v33L103 80.6"
      fill="none"
      stroke="#4bb4ff"
    />
  </svg>
);

export default Icon1;
