import React, { Component } from "react";
import tabletFree from "../../media/img/tablet_free.png";
import tabletBusy from "../../media/img/tablet_busy.png";
import tabletEnd from "../../media/img/tablet_end.png";
import Desk from "../icons/howItWork/Desk";

class OfficeSpase extends Component {
  render() {
    return (
      <div className="office_space">
        <div className="container">
          <div className="left">
            <Desk />
            <div>
            <h4 className="block_subtitle">room monitor</h4>
            <h2 className="block_title double">
              <span>Create a friendly</span>
              <span>office space</span>
            </h2>
            <p className="text">
              The tablet application can be used to monitor the status of the
              conference rooms
              </p>
            </div> 
          </div>
          <div className="right">
            <img src={tabletFree} alt="" />
            <img src={tabletEnd} alt="" />
          </div>
        </div>
      </div>
    );
  }
}
export default OfficeSpase;
