import React, { Component } from "react";
import Waitlist from "./waitlist";

class UserEmails extends Component {
    componentDidMount() {
       setTimeout(() => {
        this.props.startPresentPage("ghost");
       }, 400);
        this.props.openModal("pass");
        document.title = "Admin"
   
    }

    render() {
        return (
            <section className="wrap_user_emails">
                {this.props.showPage ? <Waitlist /> : ""}
            </section>
        );
    }
}

export default UserEmails;
