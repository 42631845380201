import React, { Component } from "react";
import Slider from "react-slick";
import slideTablet from "../../media/img/slideTablet.png";
import slidePhone from "../../media/img/slidePhone.png";
import reserved from "../../media/img/reserved.png";
import free from "../../media/img/free.png";
import doorHandle from "../../media/img/door_handle.png";
import slideTwoPhone from "../../media/img/slideTwoPhone.png";
import slideThrePhone from "../../media/img/slideThreePhone.png";
import line from "../../media/img/line.png";
import people from "../../media/img/people.png";
import slideFourPhone from "../../media/img/slideFourPhone.png";
import slideFourTablet from "../../media/img/slideFourTablet.png";
import Responsive from "react-responsive";

const Desctop = props => <Responsive {...props} minWidth={992} />;
const Mobile = props => <Responsive {...props} maxWidth={992} />;
const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 900,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    className: "scheduling_animate_slider",
    slidesToScroll: 1,
    pauseOnHover: true,
    autoplay: false,
    dotsClass: "bottomSliders-dots",
    // afterChange( currentSlide) {
    //     // let numberActive = +nextSlide;
    //     console.log(currentSlide+1);
    //     document.querySelectorAll(".list_etaps .etap").forEach(e => {
    //         e.classList.remove("active");
    //     });
    //     // document
    //     //     .querySelector(".list_etaps .etap:nth-child(" + numberActive + ")")
    //     //     .classList.add("active");
    // },

    responsive: [
        {
            breakpoint: 992,
            settings: "unslick"
        }
    ]
};

class bottomSlider extends Component {
    componentDidMount() {
        (function() {
            if (window.innerWidth >= 992) {
                var leftOf =
                    document.querySelector(".main_content.first").offsetLeft +
                    14;
                document
                    .querySelector(".scheduling_animate_slider")
                    .setAttribute("data-offsetLeft", leftOf);
            }
        })();

        function cursorAnimation(parent, x, y) {
            x = x / 2;
            y = y / 2;
            let right = document.querySelector(
                '.scheduling_animate_slider [data-index="' +
                    parent +
                    '"] .right'
            );
            right.style.WebkitTransform = `translate(${-x}px, ${-y}px)`;
            right.style.msTransform = `translate(${-x}px, ${-y}px)`;
            right.style.transform = `translate(${-x}px, ${-y}px)`;
        }

        if (document.querySelector(".wrapper_for_navigation")) {
            document
                .querySelector(".wrapper_for_navigation")
                .addEventListener("mousemove", function(el) {
                    let x = (el.pageX - this.offsetLeft) / 30;
                    let y = (el.screenY - this.offsetTop) / 30;
                    let activeSlide = document
                        .querySelector(
                            ".scheduling_animate_slider .slick-active"
                        )
                        .getAttribute("data-index");
                    cursorAnimation(activeSlide, x, y);
                });
        }
    }

    currentEtap = () => {
        let numb =  document.querySelector('.scheduling_animate_slider .bottomSliders-dots li.slick-active').innerText;
        document.querySelectorAll(".list_etaps .etap").forEach(e => {
            e.classList.remove("active");
        });
        document.querySelector('.list_etaps .etap:nth-child('+numb+')').classList.add("active");
    }
    clickPrev = () => {
        document.querySelector(".scheduling .slick-arrow.slick-prev").click();
        setTimeout(()=>{
            this.currentEtap();
        },0);
    };

    clickNext = () => {
        document.querySelector(".scheduling .slick-arrow.slick-next").click();
        setTimeout(()=>{
            this.currentEtap();
           },0)
    };


    etap = number => {
        if (window.innerWidth >= 992) {
            let current = +number.currentTarget.querySelector(".current_etap").innerText;
            document.querySelector(".bottomSliders-dots li:nth-child("+current+") button").click();
            document.querySelectorAll(".list_etaps .etap").forEach(e => {
                e.classList.remove("active");
            });
            number.currentTarget.classList.add("active");
        }
    };

    render() {
        return (
            <>
                <Desctop>
                    <div className="wrapper_for_navigation">
                        <div
                            className="overlay_left"
                            onClick={this.clickPrev}
                        />
                        <div
                            className="overlay_right"
                            onClick={this.clickNext}
                        />
                        <Slider {...settings}>
                            <div className="slide one">
                                <h2 className="block_title textAnimation">
                                    Install app on mobile and tablets
                                </h2>
                                <div className="right">
                                    <div className="figure phone">
                                        <div className="circle" />
                                        <img src={slidePhone} alt="" />
                                    </div>
                                    <div className="figure tablet">
                                        <div className="circle" />
                                        <img src={slideTablet} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="slide two">
                                <h2 className="block_title textAnimation">
                                    Create the room and pair it with tablet
                                </h2>
                                <div className="right">
                                    <div className="room">
                                        <div className="door">
                                            <div className="line_bottom" />
                                            <div className="line_top_side" />
                                            <div className="line_side" />
                                            <img
                                                src={doorHandle}
                                                alt=""
                                                className="door_handle"
                                            />
                                            <div className="room_number">
                                                <div className="number">1</div>
                                                <span className="subtitle">
                                                    room
                                                </span>
                                            </div>
                                        </div>

                                        <div className="indicator">
                                            <img src={free} alt="" />
                                            <img src={reserved} alt="" />
                                        </div>
                                    </div>

                                    <div className="room">
                                        <div className="door">
                                            <div className="line_bottom" />
                                            <div className="line_top_side" />
                                            <div className="line_side" />
                                            <img
                                                src={doorHandle}
                                                alt=""
                                                className="door_handle"
                                            />
                                            <div className="room_number">
                                            <div className="number">2</div>
                                                <span className="subtitle">
                                                    room
                                                </span>
                                            </div>
                                        </div>

                                        <div className="indicator">
                                            <img src={free} alt="" />
                                        </div>
                                    </div>

                                    <img
                                        src={slideTwoPhone}
                                        alt=""
                                        className="phone"
                                    />
                                </div>
                            </div>
                            <div className="slide thre">
                                <h2 className="block_title textAnimation">
                                    Invite your team members
                                </h2>
                                <div className="right">
                                    <img
                                        src={slideThrePhone}
                                        alt=""
                                        className="phone"
                                    />
                                    <div className="line">
                                        <img src={line} alt="" />
                                    </div>
                                    <img
                                        src={people}
                                        alt=""
                                        className="people"
                                    />
                                    <svg
                                        className="down"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 677 346"
                                    >
                                        <path
                                            d="M325.5 3.3C439.6-17 689.2 56 658.5 190.2c-11.3 49.5 18.5 132.5-66.8 154.1-48.5 12.3-208.9-47.6-331.2-19-180 42.2-299.8-103.7-248.6-185.1C90.3 15.5 176.6 29.8 325.5 3.3z"
                                            fill="#292863"
                                        />
                                        <g id="Dots">
                                            <circle
                                                className="st1"
                                                cx="509.5"
                                                cy="237.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy_3"
                                                className="st1"
                                                cx="79.5"
                                                cy="230.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy_4"
                                                className="st1"
                                                cx="96.5"
                                                cy="167.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy"
                                                className="st2"
                                                cx="224.5"
                                                cy="277.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy_2"
                                                className="st2"
                                                cx="131.5"
                                                cy="101.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy_5"
                                                className="st2"
                                                cx="543.5"
                                                cy="108.5"
                                                r="1.5"
                                            />
                                            <circle
                                                id="Ellipse_1257_copy_5-2"
                                                className="st2"
                                                cx="561.5"
                                                cy="292.5"
                                                r="1.5"
                                            />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="slide four">
                                <h2 className="block_title textAnimation">
                                    Manage your meetings
                                </h2>
                                <div className="right">
                                    <div className="circle" />
                                    <img
                                        src={slideFourPhone}
                                        alt=""
                                        className="phone"
                                    />
                                    <img
                                        src={slideFourTablet}
                                        alt=""
                                        className="tablet"
                                    />
                                </div>
                            </div>
                        </Slider>
                    </div>
                    <div className="block_window list_etaps">
                        <div className="etap active" onClick={this.etap}>
                            <div className="etap_numbers">
                                <span className="current_etap">1</span>
                                <span className="all_numbers_etap">4</span>
                            </div>
                            <div className="white_line" />
                            <h3 className="etap_title">Install apps</h3>
                            <div className="etap_description">
                                <p>
                                    Download application and install it on
                                    mobile and tablet
                                </p>
                            </div>
                        </div>
                        <div className="etap" onClick={this.etap}>
                            <div className="etap_numbers">
                                <span className="current_etap">2</span>
                                <span className="all_numbers_etap">4</span>
                            </div>
                            <div className="white_line" />
                            <h3 className="etap_title">Sign up & pair</h3>
                            <div className="etap_description">
                                <p>
                                    Register the company profile. Start creating
                                    meeting rooms in mobile app and pair them
                                    with tablets on the walls.
                                </p>
                            </div>
                        </div>
                        <div className="etap" onClick={this.etap}>
                            <div className="etap_numbers">
                                <span className="current_etap">3</span>
                                <span className="all_numbers_etap">4</span>
                            </div>
                            <div className="white_line" />
                            <h3 className="etap_title">Invite team members</h3>
                            <div className="etap_description">
                                <p>
                                    Invite team members via email or shared link
                                    to start working.
                                </p>
                            </div>
                        </div>
                        <div className="etap" onClick={this.etap}>
                            <div className="etap_numbers">
                                <span className="current_etap">4</span>
                                <span className="all_numbers_etap">4</span>
                            </div>
                            <div className="white_line" />
                            <h3 className="etap_title">Manage meetings</h3>
                            <div className="etap_description">
                                <p>
                                    Organize meetings and monitor your team’s
                                    schedule.
                                </p>
                            </div>
                        </div>
                    </div>
                </Desctop>
                <Mobile>
                    <div className="slick-active">
                        <div className="slide one">
                            <h2 className="block_title textAnimation done">
                                Install app on mobile and tablets
                            </h2>
                            <div className="right">
                                <div className="figure phone">
                                    <div className="circle" />
                                    <img src={slidePhone} alt="" />
                                </div>
                                <div className="figure tablet">
                                    <div className="circle" />
                                    <img src={slideTablet} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="block_window list_etaps">
                            <div className="etap active">
                                <div className="etap_numbers">
                                    <span className="current_etap">1</span>
                                    <span className="all_numbers_etap">4</span>
                                </div>
                                <div className="white_line" />
                                <h3 className="etap_title">Install app</h3>
                                <div className="etap_description">
                                    <p>
                                        Download application and install it on
                                        mobile and tablet
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="slide two">
                            <h2 className="block_title textAnimation">
                                Create the room and pair it with tablet
                            </h2>
                            <div className="right">
                                <div className="room">
                                    <div className="door">
                                        <div className="line_bottom" />
                                        <div className="line_top_side" />
                                        <div className="line_side" />
                                        <img
                                            src={doorHandle}
                                            alt=""
                                            className="door_handle"
                                        />
                                        <div className="room_number">
                                            <div className="number">1</div>
                                            <span className="subtitle">
                                                room
                                            </span>
                                        </div>
                                    </div>

                                    <div className="indicator">
                                        <img src={free} alt="" />
                                        <img src={reserved} alt="" />
                                    </div>
                                </div>

                                <div className="room">
                                    <div className="door">
                                        <div className="line_bottom" />
                                        <div className="line_top_side" />
                                        <div className="line_side" />
                                        <img
                                            src={doorHandle}
                                            alt=""
                                            className="door_handle"
                                        />
                                        <div className="room_number">
                                            <div className="number">2</div>
                                            <span className="subtitle">
                                                room
                                            </span>
                                        </div>
                                    </div>

                                    <div className="indicator">
                                        <img src={free} alt="" />
                                    </div>
                                </div>

                                <img
                                    src={slideTwoPhone}
                                    alt=""
                                    className="phone"
                                />
                            </div>
                        </div>
                        <div className="block_window list_etaps">
                            <div className="etap active">
                                <div className="etap_numbers">
                                    <span className="current_etap">2</span>
                                    <span className="all_numbers_etap">4</span>
                                </div>
                                <div className="white_line" />
                                <h3 className="etap_title">Sign up & pair</h3>
                                <div className="etap_description">
                                    <p>
                                        Register the company profile. Start
                                        creating meeting rooms in mobile app and
                                        pair them with tablets on the walls.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="slide thre">
                            <h2 className="block_title textAnimation">
                                Create the room and pair it with tablet
                            </h2>
                            <div className="right">
                                <img
                                    src={slideThrePhone}
                                    alt=""
                                    className="phone"
                                />
                                <div className="line">
                                    <img src={line} alt="" />
                                </div>
                                <img src={people} alt="" className="people" />
                                <svg
                                    className="down"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 677 346"
                                >
                                    <path
                                        d="M325.5 3.3C439.6-17 689.2 56 658.5 190.2c-11.3 49.5 18.5 132.5-66.8 154.1-48.5 12.3-208.9-47.6-331.2-19-180 42.2-299.8-103.7-248.6-185.1C90.3 15.5 176.6 29.8 325.5 3.3z"
                                        fill="#292863"
                                    />
                                    <g id="Dots">
                                        <circle
                                            className="st1"
                                            cx="509.5"
                                            cy="237.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy_3"
                                            className="st1"
                                            cx="79.5"
                                            cy="230.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy_4"
                                            className="st1"
                                            cx="96.5"
                                            cy="167.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy"
                                            className="st2"
                                            cx="224.5"
                                            cy="277.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy_2"
                                            className="st2"
                                            cx="131.5"
                                            cy="101.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy_5"
                                            className="st2"
                                            cx="543.5"
                                            cy="108.5"
                                            r="1.5"
                                        />
                                        <circle
                                            id="Ellipse_1257_copy_5-2"
                                            className="st2"
                                            cx="561.5"
                                            cy="292.5"
                                            r="1.5"
                                        />
                                    </g>
                                </svg>
                            </div>
                        </div>
                        <div className="block_window list_etaps">
                            <div className="etap active">
                                <div className="etap_numbers">
                                    <span className="current_etap">3</span>
                                    <span className="all_numbers_etap">4</span>
                                </div>
                                <div className="white_line" />
                                <h3 className="etap_title">
                                    Invite team members
                                </h3>
                                <div className="etap_description">
                                    <p>
                                        Invite team members via email or shared
                                        link to start working.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="slide four">
                            <h2 className="block_title textAnimation">
                                Manage your meetings
                            </h2>
                            <div className="right">
                                <div className="circle" />
                                <img
                                    src={slideFourPhone}
                                    alt=""
                                    className="phone"
                                />
                                <img
                                    src={slideFourTablet}
                                    alt=""
                                    className="tablet"
                                />
                            </div>
                        </div>
                        <div className="block_window list_etaps">
                            <div className="etap active">
                                <div className="etap_numbers">
                                    <span className="current_etap">4</span>
                                    <span className="all_numbers_etap">4</span>
                                </div>
                                <div className="white_line" />
                                <h3 className="etap_title">Manage meetings</h3>
                                <div className="etap_description">
                                    <p>
                                        Organize meetings and monitor your
                                        team’s schedule.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Mobile>
            </>
        );
    }
}

export default bottomSlider;
