import React, { Component } from "react";

import CommonProplems from "./commonProplems";
import Sheding from "./sheduling";
import SlideInstructions from "../sliders/whatPageSliderOne";
import OfficeSpase from "./OfficeSpase";
import PreviewSlider from "../sliders/previewSlider";
import HowToStart from "../sliders/HowToStartSlider";
import Banner from "./Banner";

export class HowItWork extends Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    setTimeout(() => {
      this.props.startPresentPage("other");
    }, 500);
  }
  render() {
    return (
      <div className="page_how_it_work">
        <Banner />
        <CommonProplems />
        <Sheding />
        <SlideInstructions />
        <OfficeSpase />
        <PreviewSlider />
        <HowToStart />
      </div>
    );
  }
}

export default HowItWork;
