import React from "react";
import preloader from "../../media/img/preloader.gif";
const Preloader = () => {
    return (
        <>
        <div className="preloader">
            <img src={preloader} alt="" />
            <svg
                className="logo"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 210 17"
                style={{ enableBackground: "new 0 0 210 17" }}
                xmlSpace="preserve"
            >
                <path
                    className="st0"
                    d="M210 16.7h-3.5V2.6l.1-.2s-5.4 8.7-7.8 12.6c-1.7 2.8-4.5 2.8-6.2 0-2.3-3.8-6.9-11.3-6.9-11.3l-.7-1.4v14.4h-3.5v-.4V5.2c0-.5.3-1.1.7-1.5l3.3-3.2c.4-.4 1.4-.7 1.8-.4.4.3.9 1.1 1.3 1.6 2.1 3.4 3.9 6.6 6 10 .4.6.7 1.2 1.2 2 .3-.5.5-.8.7-1.1 2.3-3.7 4.1-7.1 6.4-10.8 1.3-2 4.9-2.3 6.3-.6.5.6.9 1.5.9 2.3v9.6c-.1 1.2-.1 2.4-.1 3.6zm-33.5-3.6l-3 2.9c-.5.5-1.4.8-2.1.8h-11.6c-2.7 0-4.6-1.9-4.6-4.5V6c0-.6.3-1.2.7-1.7l3.1-3c.5-.5 1.1-.7 1.8-.7H172.4c3 0 4.9 2 4.9 4.9 0 1.6 0 4.2-.1 5.8-.1.6-.2 1.3-.7 1.8zm-2.7-.1V3.8h-15v9.8h15V13zm-22.9.1l-3 2.9c-.6.5-1.4.8-2.1.8h-11.6c-2.7 0-4.6-1.9-4.6-4.5V6c0-.6.3-1.2.7-1.7l3.1-3c.5-.5 1.1-.7 1.8-.7H146.8c3 0 4.9 2 4.9 4.9 0 1.6.1 4.2 0 5.8-.1.6-.3 1.3-.8 1.8zm-2.8-.4V3.8h-15v9.8h15v-.9zm-22.3 4l-.1-.1.1.1h-4.1l-4.4-4.9c.7.8-1.5-1.5 0 0l-2.9-3.2h7.9V3.7H109v13c-1 0-2.5-.1-3.5 0V.6h1.8c5.2 0 8.1.1 13.3 0 3.5 0 5.3 1.6 5.3 4.9v.9c0 2.5-.4 3-1.3 4.2-.8 1.1-3.4 1.2-3.4 1.2l2.9 3.1 1.7 1.8zm-24.7-4.4c-.1 2.5-1.7 4.3-4.3 4.4-5.9.1-11.3 0-17.3 0V.6h16.2c2 0 4 .4 4.8 2.3.8 1.7.5 3.4 0 3.9-.4.6-2 1.8-2 1.8s1.5 1.5 2 1.8c.7.9.6 1.5.6 1.9zm-3.6-8.6H82.8v3.5h14.7V3.7zm0 6.1H82.8v3.7h14.7V9.8zm-18 6.9c.2.1 0 0 0 0zM72.2.6h3.2v16.1h-3.2V.6zM56 16.7c-.6 0-1.2-.2-1.6-.6l-3-2.9c-.2-.2-.4-.5-.4-.8V.6h3.5v13H68v3.2h-1.2c-3-.1-8.5-.1-10.8-.1zm-25.1 0c-3 0-5-2.4-5-4.3 0-2.7 0-5.3.1-8C26 2.3 27.7.9 29.8.6c.4-.1.7 0 1.1 0h16.2c-.1 0 0 3.2 0 3.2H29.4V7H47v3.2H29.5v3.5h17.7v3.2H46c-5.3-.2-9.8-.2-15.1-.2zm-13.6 0H.4c-.1 0-.2 0-.4-.1V.6h16.8c3.4 0 5 1.6 5 4.9v6.6c0 2.6-1.8 4.6-4.5 4.6zM18.6 4h-15v9.2H18c.2 0 .6-.4.6-.6V4z"
                />
            </svg>
        </div>
        </>
    );
};
export default Preloader;
