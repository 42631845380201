import React from "react";

const SvgComponent = props => (
  <svg
    id="Icon_05"
    x="0px"
    y="0px"
    viewBox="0 0 242.1 107"
    xmlSpace="preserve"
    {...props}
  >
    <style>{".st3{fill:none;stroke:#4bb4ff}"}</style>
    <linearGradient
      id="Rectangle_886_copy_2_1_"
      gradientUnits="userSpaceOnUse"
      x1={678}
      y1={-6555.5}
      x2={750}
      y2={-6555.5}
      gradientTransform="matrix(1 0 0 -1 -591.781 -6502)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_886_copy_2"
      d="M156.2 93h-3l-.1-91.3c0-.9-.8-1.7-1.7-1.7H93.1c-.9 0-1.7.8-1.7 1.7v91.5H88c-1 0-1.7.8-1.7 1.7v10.4c0 .9.8 1.7 1.7 1.7h68.6c.9 0 1.7-.8 1.7-1.7V94.9c-.2-1-1-1.8-2.1-1.9zm-61-89h54v89h-54V4zm59 99h-64v-6h64v6zm-15-59c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm.2 7.8c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7 1 0 1.7.8 1.7 1.7 0 .9-.8 1.7-1.7 1.7z"
      fill="url(#Rectangle_886_copy_2_1_)"
    />
    <linearGradient
      id="Rectangle_886_copy_5_1_"
      gradientUnits="userSpaceOnUse"
      x1={793.003}
      y1={-6555.5}
      x2={833.92}
      y2={-6555.5}
      gradientTransform="matrix(1 0 0 -1 -591.781 -6502)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_886_copy_5"
      d="M234.2 0h-26.1c-.9 0-1.7.8-1.7 1.7v91.5H203c-1 0-1.7.8-1.7 1.7v10.4c0 .9.8 1.7 1.7 1.7h33.5s1.9-4.6 1.9-4-33.1 0-33.1 0v-6h35.6l1.4-4h-31.9V4s26.6-.8 26 0-2.2-4-2.2-4z"
      fill="url(#Rectangle_886_copy_5_1_)"
    />
    <linearGradient
      id="Rectangle_886_copy_6_1_"
      gradientUnits="userSpaceOnUse"
      x1={591.762}
      y1={-6555.5}
      x2={634}
      y2={-6555.5}
      gradientTransform="matrix(1 0 0 -1 -591.781 -6502)"
    >
      <stop offset={0} stopColor="#4bb4ff" />
      <stop offset={1} stopColor="#ec22a4" />
    </linearGradient>
    <path
      id="Rectangle_886_copy_6"
      d="M8.5 0h26.9c.9 0 1.7.8 1.7 1.7l.1 91.3h3c1 .1 1.9.9 2 1.9v10.4c0 .9-.8 1.7-1.7 1.7H6.1l-2.3-4h34.4v-6H2l-2-4h33.2V4H6.5l2-4zm14.7 44c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm.2 7.8c-1 0-1.7-.8-1.7-1.7 0-1 .8-1.7 1.7-1.7 1 0 1.7.8 1.7 1.7 0 .9-.8 1.7-1.7 1.7z"
      fill="url(#Rectangle_886_copy_6_1_)"
    />
    <path className="st3" d="M44.2 12H67.2V28H44.2z" />
    <path
      id="Rectangle_887_copy"
      className="st3"
      d="M165.2 12H188.2V28H165.2z"
    />
  </svg>
);

export default SvgComponent;
