import React, { Component } from "react";
import Slider from "react-slick";
import slide1 from "../../media/img/preview1.png";
import slide2 from "../../media/img/preview2.png";
import slide3 from "../../media/img/preview3.png";
import InstructionArr from "../icons/howItWork/InstructionArrow";

const settings = {
  className: "preview_slider",
  dots: false,
  arrow: true,
  infinite: true,
  autoplaySpeed: 0,
  speed: 600,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 1,
  pauseOnHover: true
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: "unslick"
  //     }
  //   ]
};

class PreviewSlider extends Component {
  render() {
    const prev = () => {
      document.querySelector(".wrap_preview .slick-arrow.slick-prev").click();
    };
    const next = () => {
      document.querySelector(".wrap_preview .slick-arrow.slick-next").click();
    };
    return (
      <div className="wrap_preview">
        <div className="arrow">
          <div onClick={prev}>
            <InstructionArr way="left" />
            <span>Customize the appearance of the room monitor</span>
          </div>
        </div>
        <Slider {...settings}>
          <div className="slide">
            <div className="head">
              <div>
                <h3>Start a meeting right now by using “Quick reservation”</h3>
              </div>
              <button className="btn clear ">
                <span>show interface</span>
              </button>
            </div>
            <img src={slide1} alt="" />
          </div>

          <div className="slide">
            <div className="head">
              <div>
                <h3>Finish earlier or extend the meeting</h3>
              </div>
              <button className="btn clear ">
                <span>show interface</span>
              </button>
            </div>
            <img src={slide2} alt="" />
          </div>

          <div className="slide">
            <div className="head">
              <div>
                <h3>Finish earlier or extend the meeting</h3>
              </div>
              <button className="btn clear ">
                <span>show interface</span>
              </button>
            </div>
            <img src={slide3} alt="" />
          </div>
        </Slider>
        <div className="arrow">
          <div onClick={next}>
            <InstructionArr way="right" />
            <span>
              Finished earlier? End a meeting by tapping on “Finish now”
            </span>
          </div>
        </div>
       
        <div className="other_panel">
          <div onClick={prev}>
            <InstructionArr way="left" />
            <span>
            Customize the appearance of the room monitor
            </span>
          </div>
          <div onClick={next}>
            <InstructionArr way="right" />
            <span>
            Finished earlier? End a meeting by tapping on “Finish now”
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default PreviewSlider;
