import React, { Component } from "react";
import Slider from "react-slick";
import InstructionArr from "../icons/howItWork/InstructionArrow";
import { Numbers } from "../icons/howItWork/Numbers";
import Icon1 from "../icons/howItWork/HowToStart/Icon1";
import Icon2 from "../icons/howItWork/HowToStart/Icon2";
import Icon3 from "../icons/howItWork/HowToStart/Icon3";
import Icon4 from "../icons/howItWork/HowToStart/Icon4";
import Icon5 from "../icons/howItWork/HowToStart/Icon5";
import Icon6 from "../icons/howItWork/HowToStart/Icon6";
import Icon7 from "../icons/howItWork/HowToStart/Icon7";
import Icon8 from "../icons/howItWork/HowToStart/Icon8";
const settings = {
  className: "how_to_start_slider",
  dots: false,
  arrow: false,
  infinite: true,
  autoplaySpeed: 0,
  speed: 600,
  autoplay: false,
  slidesToScroll: 1,
  slidesToShow: 4,
  pauseOnHover: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

class HowToStart extends Component {
  render() {
    const prev = () => {
      document
        .querySelector(".how_to_start_slider  .slick-arrow.slick-prev")
        .click();
    };
    const next = () => {
      document
        .querySelector(".how_to_start_slider  .slick-arrow.slick-next")
        .click();
    };
    return (
      <div className="how_to_start">
        <div className="container">
          <h2 className="block_subtitle">how to start</h2>
          <Slider {...settings}>
            <div className="slide">
              <div className="upper">
                <Numbers number="1" />
                <h3>Make sure that the Wi-Fi connection is available</h3>
              </div>
              <div className="icon">
                <Icon1 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="2" />
                <h3>Download app for mobile and tablet from the store</h3>
              </div>
              <div className="icon">
                <Icon2 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="3" />
                <h3>
                  Create your
                  <br /> company profile
                </h3>
              </div>
              <div className="icon">
                <Icon3 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="4" />
                <h3>
                  Invite
                  <br />
                  teammates
                </h3>
              </div>
              <div className="icon">
                <Icon4 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="5" />
                <h3>Add all meeting rooms of your office</h3>
              </div>
              <div className="icon">
                <Icon5 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="6" />
                <h3>Pair each tablet with a relevant room </h3>
              </div>
              <div className="icon">
                <Icon6 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="7" />
                <h3>Organize place for tablets on the wall near the room</h3>
              </div>
              <div className="icon">
                <Icon7 />
              </div>
            </div>
            <div className="slide">
              <div className="upper">
                <Numbers number="8" />
                <h3>Start managing your meetings</h3>
              </div>
              <div className="icon">
                <Icon8 />
              </div>
            </div>
          </Slider>
          <div className="line_arrows">
            <InstructionArr way="left" left={prev} />
            <InstructionArr way="right" right={next} />
          </div>
        </div>
      </div>
    );
  }
}

export default HowToStart;
