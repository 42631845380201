import React from "react";
import ReactDOM from "react-dom";
import "./media/scss/style.scss";
import App from "./components/App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as serviceWorker from "./serviceWorker";
import store from "./reducer/store";

ReactDOM.render(
    <App store={store} />,

    document.getElementById("root")
);

serviceWorker.unregister();
