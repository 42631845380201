import React, {useState, useEffect} from 'react';
import "../../media/scss/cookies.scss";
import CookieConsent from "react-cookie-consent";

const CookiesAlert = () => {

  return (
    <CookieConsent
      location="bottom"
      buttonText="I agree"
      cookieName="delibroom-cookie-accepted"
      style={{ background: "#2B373B", alignItems: 'center'}}
      buttonStyle={{
        padding: '1.0em 1.8em',
        background: '#4bb4ff',
        boxShadow: '0px 6px 8px 0px rgb(75 180 255 / 30%)',
        color: '#fff',
      }}
      buttonClasses={'btn bright_blue'}
      expires={150}
    >
      We use cookies on our website to provide website functionality, analyze traffic, enable social media functionality.
      <br />
      <a style={{color: '#4bb4ff'}} href={'https://delibroom.com/privacy-policy'}>Our Privacy Policy</a> provides more information on particular types of cookies we gather and explains how to update the cookie settings.
      <br />
      Otherways, if not agree you may not use our website.
    </CookieConsent>
  )
};

export default CookiesAlert;