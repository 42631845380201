import React from "react";
import { Link } from "react-scroll";
import Responsive from "react-responsive";
const Large = props => <Responsive {...props} minHeight={626} />;
const Medium = props => <Responsive {...props} maxHeight={625} />;

function LeftNavigation() {
    return (
        <>
            <div className="left_navigation">
                <Large>
                    <Link
                        activeClass="active startScroll"
                        spy={true}
                        smooth={true}
                        offset={-500}
                        duration={500}
                        to="how-to-use"
                    >
                        How to use
                    </Link>
                    
                </Large>
                <Medium>
                        <Link
                            activeClass="active startScroll"
                            spy={true}
                            smooth={true}
                            offset={0}
                            duration={500}
                            to="how-to-use"
                        >
                            How to use
                        </Link>
                    </Medium>
                <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={0}
                    duration={500}
                    to="feature"
                >
                    Features
                </Link>
                <Large>
                    <Link
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                        to="pricing"
                        className="pricingLink"
                    >
                        Pricing
                    </Link>
                </Large>

                <Medium>
                    <Link
                        activeClass="active"
                        spy={true}
                        smooth={true}
                        offset={90}
                        duration={500}
                        to="pricing"
                        className="pricingLink"
                    >
                        Pricing
                    </Link>
                </Medium>
                {/* <Link
                    activeClass="active"
                    spy={true}
                    smooth={true}
                    offset={5}
                    duration={500}
                    to="video"
                >
                    Video
                </Link> */}
            </div>
        </>
    );
}

export default LeftNavigation;
