import React from "react";
import Email from "./email";
import Pesent from "./present";
import Password from "./password";

let modal = React.createRef();

const ModalBox = props => {
    let activeBlock = props.activeModal;
    if (activeBlock === "present") {
        setTimeout(() => {
            modal.current.className += "modal_box active present";
        }, 10);
    } else if (activeBlock === "email") {
        setTimeout(() => {
            modal.current.className += "modal_box active email";
        }, 10);
    } else if (activeBlock === "pass") {
        setTimeout(() => {
            modal.current.className += "modal_box active pass";
        }, 10);
    }

   

    let focus = el => {
        if (el.target.value.length >= 4) {
            el.target.classList.add("focus");
            el.target.classList.remove("empty");
        } else {
            el.target.classList.add("empty");
            el.target.classList.add("focus");
        }
    };

    return (
        <div className="modal_box " ref={modal}>
            <div className="overlay" onClick={props.closeModal} />
            <div className="modal_window">
                <div className="modal_close" onClick={props.closeModal}>
                    <span />
                    <span />
                </div>
                {activeBlock === "present" ? (
                    <Pesent
                        closeModal={props.closeModal}
                        openModal={props.openModal}
                    />
                ) : activeBlock === "email" ? (
                    <Email closeModal={props.closeModal} />
                ) : activeBlock === "pass" ? (
                    <Password focus={focus} closeModal={props.closeModal} showPage={props.showPage} verification={props.verification} />
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default ModalBox;
