import React, { Component } from "react";
import { NavLink } from "react-router-dom";

let footer = React.createRef();
class Footer extends Component {
    state = {
        isOpen: false
    };
    componentDidMount = () => {
        this.props.startPresentPage("footer");
    }
    
    render() {
        let openSocials = e => {
            this.setState(prevState => ({
                isOpen: !prevState.isOpen
            }));
        };

     

        let text = this.props.footer.footerText;
        let links = this.props.footer.links;

        return (
            <footer ref={footer} className={`${this.props.showFooter} ${this.props.footerVisible}`}>
                <div
                    className={`wrap_socials ${
                        this.state.isOpen ? "active" : ""
                    }`}
                >
                    <div className="soc_btn" onClick={openSocials}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="20px"
                            height="15.5px"
                        >
                            <path
                                fillRule="evenodd"
                                stroke="rgb(255, 255, 255)"
                                strokeWidth="1px"
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fill="none"
                                d="M3.500,0.500 C5.157,0.500 6.500,1.843 6.500,3.500 C6.500,5.157 5.157,6.500 3.500,6.500 C1.843,6.500 0.500,5.157 0.500,3.500 C0.500,1.843 1.843,0.500 3.500,0.500 Z"
                            />
                            <path
                                fillRule="evenodd"
                                stroke="rgb(255, 255, 255)"
                                strokeWidth="1px"
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fill="none"
                                d="M13.500,0.500 C15.157,0.500 16.500,1.843 16.500,3.500 C16.500,5.157 15.157,6.500 13.500,6.500 C11.843,6.500 10.500,5.157 10.500,3.500 C10.500,1.843 11.843,0.500 13.500,0.500 Z"
                            />
                            <path
                                fillRule="evenodd"
                                stroke="rgb(255, 255, 255)"
                                strokeWidth="1px"
                                strokeLinecap="butt"
                                strokeLinejoin="miter"
                                fill="none"
                                d="M8.500,8.500 C10.157,8.500 11.500,9.843 11.500,11.500 C11.500,13.157 10.157,14.500 8.500,14.500 C6.843,14.500 5.500,13.157 5.500,11.500 C5.500,9.843 6.843,8.500 8.500,8.500 Z"
                            />
                        </svg>
                        <span>Socials</span>
                    </div>
                    <div className="socials">
                        <a
                            href={links.socialLinks.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="facebook"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="11.5px"
                                height="22.5px"
                            >
                                <path
                                    fillRule="evenodd"
                                    strokeWidth="1px"
                                    strokeLinecap="butt"
                                    strokeLinejoin="miter"
                                    d="M10.501,7.500 L7.500,7.500 L7.500,5.500 C7.500,4.604 8.108,4.500 8.500,4.500 C8.891,4.500 10.501,4.500 10.501,4.500 L10.501,0.500 L7.500,0.500 C3.823,0.497 2.489,3.601 2.501,5.500 C2.529,5.545 2.501,7.500 2.501,7.500 L0.501,7.500 L0.501,11.500 L2.501,11.500 L2.501,21.500 L7.500,21.500 L7.500,11.500 L10.501,11.500 L10.501,7.500 Z"
                                />
                            </svg>
                        </a>
                        <a
                            href={links.socialLinks.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="twitter"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="26.5px"
                                height="22.5px"
                            >
                                <path
                                    fillRule="evenodd"
                                    strokeWidth="1px"
                                    strokeLinecap="butt"
                                    strokeLinejoin="miter"
                                    d="M8.501,21.500 C18.487,21.291 22.501,13.400 22.501,6.499 C22.501,6.275 22.313,6.806 23.327,6.251 C24.331,5.508 24.810,4.554 25.501,3.500 C24.567,3.923 23.520,3.380 22.501,3.500 C23.575,2.845 24.425,2.394 24.810,1.179 C23.806,1.787 22.298,1.663 21.153,1.894 C19.207,-0.207 15.563,0.519 13.500,2.500 C12.167,3.778 12.085,4.686 12.501,6.499 C8.375,6.291 5.105,4.757 2.500,1.498 C1.136,3.887 2.249,6.965 3.975,8.471 C2.723,8.423 2.127,8.484 1.497,8.081 C1.497,8.105 1.497,8.121 1.497,8.145 C1.497,10.629 3.223,12.770 5.615,13.265 C4.861,13.472 4.070,13.505 3.301,13.353 C3.976,15.478 5.898,16.939 8.093,16.979 C6.274,18.433 4.030,19.224 1.724,19.224 C1.317,19.224 0.909,19.200 0.500,19.152 C2.838,20.685 5.708,21.500 8.501,21.500 "
                                />
                            </svg>
                        </a>
                        <a
                            href={links.socialLinks.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="linkedin"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24.5px"
                                height="23.5px"
                            >
                                <path
                                    fillRule="evenodd"
                                    strokeWidth="1px"
                                    strokeLinecap="butt"
                                    strokeLinejoin="miter"
                                    d="M23.486,13.988 L23.486,22.501 L18.500,22.500 L18.500,14.500 C18.500,12.505 17.850,11.202 16.068,11.202 C14.708,11.202 13.856,11.612 13.500,12.500 C13.370,12.817 13.500,14.056 13.500,14.500 L13.500,22.500 L8.500,22.500 C8.500,22.500 8.566,8.894 8.500,7.500 L13.500,7.500 L13.378,9.760 C13.368,9.776 13.355,9.793 13.346,9.808 L13.378,9.808 L13.378,9.760 C14.032,8.749 15.201,7.307 17.817,7.307 C21.057,7.307 23.486,9.429 23.486,13.988 ZM3.301,0.499 C1.616,0.499 0.514,1.607 0.514,3.064 C0.514,4.488 1.584,5.630 3.235,5.630 L3.268,5.630 C4.986,5.630 6.054,4.489 6.054,3.064 C6.022,1.607 4.986,0.499 3.301,0.499 ZM0.500,22.500 L5.500,22.500 L5.500,7.500 L0.500,7.500 L0.500,22.500 Z"
                                />
                            </svg>
                        </a>
                        <a
                            href={`mailto:${links.support}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="envelope"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="18px"
                            >
                                <path
                                    fillRule="evenodd"
                                    fill="rgb(255, 255, 255)"
                                    d="M21.000,0.000 L2.999,0.000 C1.343,0.000 -0.001,1.343 -0.001,3.000 L-0.001,15.000 C-0.001,16.657 1.343,18.000 2.999,18.000 L21.000,18.000 C22.657,18.000 24.001,16.657 24.001,15.000 L24.001,3.000 C24.001,1.343 22.657,0.000 21.000,0.000 ZM16.000,8.000 L22.999,2.000 C23.045,2.141 22.999,2.845 22.999,3.000 L22.999,15.000 C22.999,15.104 22.460,15.196 22.440,15.294 L16.000,8.000 ZM21.000,1.000 C21.099,1.000 21.906,0.981 22.000,1.000 L12.000,10.000 L1.999,1.000 C2.093,0.981 2.900,1.000 2.999,1.000 L21.000,1.000 ZM1.559,15.293 C1.538,15.195 0.999,15.104 0.999,15.000 L0.999,3.000 C0.999,2.845 0.955,2.141 0.999,2.000 L8.000,8.000 L1.559,15.293 ZM2.999,17.000 C2.848,17.000 2.138,16.042 1.999,16.000 L9.000,9.000 L12.000,11.000 C12.139,11.121 11.826,11.250 12.000,11.250 C12.173,11.250 12.348,11.190 12.488,11.068 L15.000,9.000 L22.000,16.000 C21.862,16.042 21.152,17.000 21.000,17.000 L2.999,17.000 Z"
                                />
                            </svg>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                width="24px"
                                height="18px"
                            >
                                <path
                                    fillRule="evenodd"
                                    fill="rgb(242, 50, 109)"
                                    d="M21.000,18.000 L2.999,18.000 C1.770,18.000 0.716,17.259 0.253,16.200 C0.523,16.447 0.914,16.834 1.025,16.974 L0.999,17.000 C1.079,17.056 1.070,17.031 1.025,16.974 L9.000,9.000 L12.000,12.000 C11.789,12.014 11.581,12.132 12.000,12.000 C12.139,12.122 12.173,11.988 12.000,12.000 L15.000,9.000 L21.994,16.993 C21.907,17.087 21.885,17.114 22.000,17.000 L21.994,16.993 C22.236,16.732 23.014,15.928 22.999,16.000 L16.000,8.000 L23.534,1.408 C23.826,1.871 24.001,2.414 24.001,3.000 L24.001,15.000 C24.001,16.657 22.657,18.000 21.000,18.000 ZM12.000,10.000 L1.473,0.431 C1.922,0.164 2.439,0.000 2.999,0.000 L21.000,0.000 C21.560,0.000 22.077,0.163 22.526,0.430 L12.000,10.000 ZM8.000,8.000 L0.139,15.860 C0.057,15.586 -0.001,15.301 -0.001,15.000 L-0.001,3.000 C-0.001,2.414 0.174,1.870 0.465,1.408 L8.000,8.000 Z"
                                />
                            </svg>
                        </a>
                    </div>
                </div>

                <div className="copyright center">
                    <NavLink to="/terms-of-use" className="privacyPolicy terms" onClick={this.props.pageScroll}>
                        Terms and Conditions
                    </NavLink> 
                     <NavLink to="/privacy-policy" className="privacyPolicy" onClick={this.props.pageScroll}>
                        Privacy Policy
                    </NavLink>
                    <p>{text.copyright}</p>
                </div>
                <div className="copyright">
                    <a
                        href={links.home}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {text.author}
                    </a>
                </div>
            </footer>
        );
    }
}
export default Footer;
