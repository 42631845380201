import React, { Component } from "react";

class Background extends Component {
    componentDidMount() {
        setTimeout(() => {
            document.querySelector(".back_dots").classList.add("active");
            setTimeout(() => {
                document.querySelector(".back_dots").classList.remove("active");
            }, 60 * 1000 * 3);
        },9000);
    }
    render() {
        return (
            <>
                <svg
                    className="back_dots"
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 1073 539"
                    space="preserve"
                >
                    <path
                        className="st0 figure_1"
                        d="M247.4,284.8c3.8,0,6.9,1.7,6.9,3.9s-3.1,3.9-6.9,3.9s-6.9-1.7-6.9-3.9S243.6,284.8,247.4,284.8L247.4,284.8z"
                    />
                    <path
                        className="st0 figure_2"
                        d="M103,259.8c2.7,0,4.9,1.5,4.9,3.2s-2.2,3.2-4.9,3.2s-4.9-1.5-4.9-3.2S100.3,259.8,103,259.8L103,259.8z"
                    />
                    <path
                        className="st0 figure_3"
                        d="M7.2,304.2c4,0,7.2,1.9,7.2,4.2s-3.2,4.2-7.2,4.2S0,310.8,0,308.5S3.2,304.2,7.2,304.2z"
                    />
                    <path
                        className="st0 figure_4"
                        d="M171.5,429.5c1.8,0,3.3,0.9,3.3,2s-1.5,2-3.3,2s-3.3-0.9-3.3-2S169.6,429.5,171.5,429.5z"
                    />
                    <path
                        className="st0 figure_5"
                        d="M112.5,508c2.5,0,4.6,1.3,4.6,2.9c0,1.6-2,2.9-4.6,2.9s-4.6-1.3-4.6-2.9C107.9,509.4,109.9,508,112.5,508 L112.5,508z"
                    />
                    <path
                        className="st0 figure_6"
                        d="M137.5,533.1c2.5,0,4.6,1.3,4.6,2.9s-2.1,2.9-4.6,2.9s-4.6-1.3-4.6-2.9S135,533.1,137.5,533.1L137.5,533.1z"
                    />
                    <path
                        className="st0 figure_7"
                        d="M134.5,56.6c3.8,0,6.8,1.8,6.8,3.9s-3.1,3.9-6.8,3.9s-6.8-1.8-6.8-3.9S130.7,56.6,134.5,56.6z"
                    />
                    <path
                        className="st0 figure_8"
                        d="M175,50c2.9,0,5.2,1.3,5.2,2.9s-2.3,2.9-5.2,2.9s-5.2-1.3-5.2-2.9S172.2,50,175,50z"
                    />
                    <path
                        className="st0 figure_9"
                        d="M510.4,28.2c3.8,0,6.8,1.7,6.8,3.9s-3.1,3.9-6.8,3.9s-6.8-1.7-6.8-3.9S506.7,28.2,510.4,28.2z"
                    />
                    <path
                        className="st0 figure_10"
                        d="M474.7,0c4,0,7.2,1.9,7.2,4.2c0,2.3-3.2,4.2-7.2,4.2s-7.2-1.9-7.2-4.2S470.8,0,474.7,0z"
                    />
                    <path
                        className="st0 figure_11"
                        d="M1068.4,52.1c2.5,0,4.6,1.3,4.6,2.9s-2,2.9-4.6,2.9s-4.6-1.3-4.6-2.9S1065.9,52.1,1068.4,52.1L1068.4,52.1z"
                    />
                    <path
                        className="st0 figure_12"
                        d="M1045.2,103.2c3.3,1,5.3,3.6,4.7,5.9s-3.9,3.2-7.2,2.2s-5.4-3.6-4.7-5.9S1042,102.2,1045.2,103.2z"
                    />
                    <path
                        className="st0 figure_13"
                        d="M1034.5,88.6c2.5,0,4.6,1.3,4.6,2.9s-2,2.9-4.6,2.9s-4.6-1.3-4.6-2.9S1032,88.6,1034.5,88.6z"
                    />
                    <path
                        className="st0 figure_14"
                        d="M1017.6,99.5c2.7,0,4.9,1.5,4.9,3.2s-2.2,3.2-4.9,3.2s-4.9-1.5-4.9-3.2S1014.9,99.5,1017.6,99.5z"
                    />
                    <path
                        className="st0 figure_15"
                        d="M1012.8,401c3.3,1,5.3,3.6,4.7,5.9s-3.9,3.2-7.2,2.2s-5.3-3.6-4.7-5.9S1009.6,400,1012.8,401z"
                    />
                    <path
                        className="st0 figure_16"
                        d="M1016.1,468.1c2.5,0,4.6,1.3,4.6,2.9s-2,2.9-4.6,2.9s-4.6-1.3-4.6-2.9S1013.6,468.1,1016.1,468.1z"
                    />
                    <path
                        className="st0 figure_17"
                        d="M998.2,480.8c2.7,0,4.9,1.5,4.9,3.3s-2.2,3.3-4.9,3.3s-4.9-1.5-4.9-3.3S995.5,480.8,998.2,480.8z"
                    />
                    <path
                        className="st0 figure_19"
                        d="M1048.6,520c2.5,0,4.6,1.3,4.6,2.9s-2,2.9-4.6,2.9s-4.6-1.3-4.6-2.9S1046,520,1048.6,520z"
                    />
                </svg>
                <div className="layer" />
            </>
        );
    }
}

export default Background;
