import React, { Component } from "react";
let contact = React.createRef();
export class Contact extends Component {
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        setTimeout(() => {
            this.props.startPresentPage("other");
            
            contact.current.classList.remove("active");
            contact.current.classList.add("active");
            this.props.contactPage('contact_page');
        }, 500);
    }
    render() {
        return (
            <>
                <section className="main_content contact" ref={contact}>
                    <div className="left_signature">
                        <span>{this.props.author}</span>
                    </div>
                    <div className="block_window">
                        <div className="bubble">
                            <div className="planet" />
                        </div>

                        <div className="contact_col">
                            <h1 className="block_title">Contact</h1>
                            <div className="mobile_section socials">
                                <a
                                    href={this.props.links.socialLinks.facebook}
                                    className="facebook"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Facebook
                                </a>
                                <a
                                    href={this.props.links.socialLinks.twitter}
                                    className="twitter"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Twitter
                                </a>
                                <a
                                    href={this.props.links.socialLinks.linkedin}
                                    className="likedin"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    LinkedIn
                                </a>
                            </div>
                        </div>
                        <div className="contact_col">
                            <div>
                                <h2 className="title">
                                    Need help installing, configuring, or
                                    troubleshooting? We’ll get back to you
                                    before you know it.
                                </h2>
                                <p className="subtitle">
                                    (usually within 24 hours during workdays)
                                </p>
                            </div>
                            <a
                                href={`mailto:${this.props.links.support}`}
                                target="_blank"
                                className="envelope"
                                rel="noopener noreferrer"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="18px"
                                >
                                    <path
                                        fillRule="evenodd"
                                        fill="rgb(255, 255, 255)"
                                        d="M21.000,0.000 L2.999,0.000 C1.343,0.000 -0.001,1.343 -0.001,3.000 L-0.001,15.000 C-0.001,16.657 1.343,18.000 2.999,18.000 L21.000,18.000 C22.657,18.000 24.001,16.657 24.001,15.000 L24.001,3.000 C24.001,1.343 22.657,0.000 21.000,0.000 ZM16.000,8.000 L22.999,2.000 C23.045,2.141 22.999,2.845 22.999,3.000 L22.999,15.000 C22.999,15.104 22.460,15.196 22.440,15.294 L16.000,8.000 ZM21.000,1.000 C21.099,1.000 21.906,0.981 22.000,1.000 L12.000,10.000 L1.999,1.000 C2.093,0.981 2.900,1.000 2.999,1.000 L21.000,1.000 ZM1.559,15.293 C1.538,15.195 0.999,15.104 0.999,15.000 L0.999,3.000 C0.999,2.845 0.955,2.141 0.999,2.000 L8.000,8.000 L1.559,15.293 ZM2.999,17.000 C2.848,17.000 2.138,16.042 1.999,16.000 L9.000,9.000 L12.000,11.000 C12.139,11.121 11.826,11.250 12.000,11.250 C12.173,11.250 12.348,11.190 12.488,11.068 L15.000,9.000 L22.000,16.000 C21.862,16.042 21.152,17.000 21.000,17.000 L2.999,17.000 Z"
                                    />
                                </svg>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                    width="24px"
                                    height="18px"
                                >
                                    <path
                                        fillRule="evenodd"
                                        fill="rgb(242, 50, 109)"
                                        d="M21.000,18.000 L2.999,18.000 C1.770,18.000 0.716,17.259 0.253,16.200 C0.523,16.447 0.914,16.834 1.025,16.974 L0.999,17.000 C1.079,17.056 1.070,17.031 1.025,16.974 L9.000,9.000 L12.000,12.000 C11.789,12.014 11.581,12.132 12.000,12.000 C12.139,12.122 12.173,11.988 12.000,12.000 L15.000,9.000 L21.994,16.993 C21.907,17.087 21.885,17.114 22.000,17.000 L21.994,16.993 C22.236,16.732 23.014,15.928 22.999,16.000 L16.000,8.000 L23.534,1.408 C23.826,1.871 24.001,2.414 24.001,3.000 L24.001,15.000 C24.001,16.657 22.657,18.000 21.000,18.000 ZM12.000,10.000 L1.473,0.431 C1.922,0.164 2.439,0.000 2.999,0.000 L21.000,0.000 C21.560,0.000 22.077,0.163 22.526,0.430 L12.000,10.000 ZM8.000,8.000 L0.139,15.860 C0.057,15.586 -0.001,15.301 -0.001,15.000 L-0.001,3.000 C-0.001,2.414 0.174,1.870 0.465,1.408 L8.000,8.000 Z"
                                    />
                                </svg>
                                <span>{this.props.links.support}</span>
                            </a>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Contact;
